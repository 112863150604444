import {Dealer} from "../store/types";
import {RequestHeader} from "./RequestHeader";

const ApiUrl = process.env.REACT_APP_BACKEND_URL;
/**
 * Returns a list of all Dealers for Importer
 * @param importer
 */
export function getDealersByImporter(token: string, importer: string): Promise<Array<Dealer>> {
  let request = {
    id: importer,
  };
  return fetch(ApiUrl + "/getDealersByImporter", {
    method: "POST",
    headers: RequestHeader.createPutHeader(token),
    body: JSON.stringify(request)
  })
  .catch((err) => {
    throw new Error(err);
  })
  .then(async (response) => {
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return await response.json();
  });
}
