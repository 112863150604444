import React, { useContext, useState } from "react";
import { PTabsBar } from "@porsche-design-system/components-react";
import "./index.scss";
import { MyNumbersTab } from "./MyNumbersTab";
import { SearchTab } from "./SearchTab";
import UserContext from "../../store/UserContext";
import { USER_ROLE } from "../../store/user";
import { TransferReservationTab } from "./TransferReservationTab";

export default function SearchModelForm() {
	const [triggerUpdate, setTriggerUpdate] = useState(true);
	const [triggerUpdateSearchTab, handleTriggerUpdateSearchTab] = useState(false);
	const [triggerUpdateTransferTab, handleTriggerUpdateTransferTab] = useState(false);
	const { roles, isDealer} = useContext(UserContext);
	const [showImporterTransfer] = useState<boolean>(roles.includes(USER_ROLE.VIP_ORDER_MANAGEMENT) || roles.includes(USER_ROLE.IMPORTER));
	const [updateLists, setUpdateLists] = useState<boolean>(true);
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

	const handleTabChange = (e: CustomEvent) => {
		let tabIndex = e.detail.activeTabIndex;
		setActiveTabIndex(tabIndex);

		switch (tabIndex) {
			case 0:
				document.getElementById("tab-panel-0").hidden = false;
				document.getElementById("tab-panel-1").hidden = true;
				document.getElementById("tab-panel-2").hidden = true;
				setTriggerUpdate(true);
				break;
			case 1:
				document.getElementById("tab-panel-0").hidden = true;
				document.getElementById("tab-panel-1").hidden = false;
				document.getElementById("tab-panel-2").hidden = true;
				handleTriggerUpdateSearchTab(!triggerUpdateSearchTab);
				setTriggerUpdate(false);
				break;
			default:
				document.getElementById("tab-panel-0").hidden = true;
				document.getElementById("tab-panel-1").hidden = true;
				document.getElementById("tab-panel-2").hidden = false;
				handleTriggerUpdateTransferTab(!triggerUpdateTransferTab);
				setUpdateLists(true);
				setTriggerUpdate(false);
		}
	};

	return (
		<div>
			<PTabsBar activeTabIndex={activeTabIndex} onTabChange={handleTabChange} className="tab-bar">
				<button type={"button"} id={"tab-item-0"} aria-controls={"tab-panel-0"}>
					My Numbers
				</button>
				<button type={"button"} id={"tab-item-1"} aria-controls={"tab-panel-1"}>
					Search
				</button>
				{showImporterTransfer && !isDealer && (
					<button type={"button"} id={"tab-item-2"} aria-controls={"tab-panel-2"}>
					Transfer
				</button> )}
			</PTabsBar>

			<div className={"tab-panel"}>
				<div id="tab-panel-0" role="tabpanel" aria-labelledby="tab-item-0">
					<MyNumbersTab triggerUpdate={triggerUpdate} activeTab={activeTabIndex} />
				</div>
				<div id="tab-panel-1" hidden role="tabpanel" aria-labelledby="tab-item-1">
					<SearchTab triggerUpdate={triggerUpdateSearchTab} />
				</div>
				{showImporterTransfer && !isDealer && (
					<div id="tab-panel-2" hidden role="tabpanel" aria-labelledby="tab-item-2">
						<TransferReservationTab triggerUpdate={triggerUpdateTransferTab} triggerUpdateLists={updateLists} />
					</div>
				)}
			</div>
		</div>
	);
}
