import moment from "moment";
import { LenaAction } from "./actions";
import { CreateModelState } from "./types";
import { splitDateTime } from "../utils";

export function lenaReducer(state: CreateModelState, action: LenaAction): CreateModelState {
	const newState = { ...state };
	newState.triggerSave = false;
	newState.triggerUpdate = false;
	newState.triggerUpdateList = false;
	let maxLimitedNumber;
	switch (action.type) {
		case "CHANGE_IDENT":
			newState.createModel.orderType = action.value;
			newState.createModelValid.orderTypeValid = newState.createModel.orderType.length === 5;
			break;
		case "CHANGE_DESCRIPTION":
			newState.createModel.description = action.value;
			newState.createModelValid.descriptionValid = newState.createModel.description.length > 0;
			break;
		case "CHANGE_START_DATE":
			newState.createModel.startDate = action.value;
			newState.createModelValid.startDateValid = !!newState.createModel.startDate;
			break;
		case "CHANGE_START_TIME":
			newState.createModel.startTime = action.value;
			newState.createModelValid.startTimeValid = !!newState.createModel.startTime;
			break;
		case "CHANGE_START_OF_SALE_DATE":
			newState.createModel.startOfSaleDate = action.value;
			newState.createModelValid.startOfSaleDateValid = !!newState.createModel.startOfSaleDate;
			break;
		case "CHANGE_START_OF_SALE_TIME":
			newState.createModel.startOfSaleTime = action.value;
			newState.createModelValid.startOfSaleTimeValid = !!newState.createModel.startOfSaleTime;
			break;
		case "CHANGE_MAX_LIMITED_NUMBER":
			newState.createModel.maxLimitedNumber = action.value;
			maxLimitedNumber = newState.createModel.maxLimitedNumber;
			// eslint-disable-next-line eqeqeq
			newState.createModelValid.maxLimitedNumberValid = parseInt(maxLimitedNumber).toString() == maxLimitedNumber && parseInt(maxLimitedNumber) > 0;
			break;
		case "SUBMIT":
			//checking order type validation
			newState.createModelValid.orderTypeValid = newState.createModel.orderType.length === 5;
			//checking description validation
			newState.createModelValid.descriptionValid = newState.createModel.description.length > 0;
			//checking startdate validation
			if (newState.createModel.startDate !== "" && newState.createModel.startTime === "") {
				newState.createModelValid.startDateValid = true;
				newState.createModelValid.startTimeValid = false;
			} else if (newState.createModel.startDate === "" && newState.createModel.startTime !== "") {
				newState.createModelValid.startDateValid = false;
				newState.createModelValid.startTimeValid = true;
			} else {
				newState.createModelValid.startDateValid = true;
				newState.createModelValid.startTimeValid = true;
			}
			//checking startOfSaleDate validation
			if (newState.createModel.startOfSaleDate !== "" && newState.createModel.startOfSaleTime === "") {
				newState.createModelValid.startOfSaleDateValid = true;
				newState.createModelValid.startOfSaleTimeValid = false;
			} else if (newState.createModel.startOfSaleDate === "" && newState.createModel.startOfSaleTime !== "") {
				newState.createModelValid.startOfSaleDateValid = false;
				newState.createModelValid.startOfSaleTimeValid = true;
			} else {
				newState.createModelValid.startOfSaleDateValid = true;
				newState.createModelValid.startOfSaleTimeValid = true;
			}

			//checking max number validation
			maxLimitedNumber = newState.createModel.maxLimitedNumber;
			// eslint-disable-next-line eqeqeq
			newState.createModelValid.maxLimitedNumberValid = parseInt(maxLimitedNumber).toString() == maxLimitedNumber && parseInt(maxLimitedNumber) > 0;

			let modelValidKeys = Object.keys(newState.createModelValid);
			let isValidSubmit = true;
			for (const key of modelValidKeys) {
				if (!state.createModelValid[key]) isValidSubmit = false;
			}
			if (isValidSubmit === false) break;
			if (newState.updateView) {
				newState.triggerUpdate = true;
			} else {
				newState.triggerSave = true;
			}
			break;
		case "RESET_FORM":
			newState.createModel.orderType = "";
			newState.createModel.description = "";
			newState.createModel.startDate = "";
			newState.createModel.startTime = "";
			newState.createModel.startOfSaleDate = "2030-01-01";
			newState.createModel.startOfSaleTime = "00:00";
			newState.createModel.maxLimitedNumber = "";
			newState.createModel.importerStartOfSaleDateTime = [];
			newState.updateView = false;
			break;
		case "DELETE":
			newState.triggerUpdateList = true;
			break;
		case "SWITCH_TO_UPDATE_MODEL":
			newState.createModel.orderType = action.valueOrderType;
			newState.createModel.description = action.valueDescription;
			newState.createModel.importerStartOfSaleDateTime = action.valueImporterStartOfSaleDateTime;

			if (!action.valueStartDateTime) {
				newState.createModel.startDate = "";
				newState.createModel.startTime = "";
				newState.savedStartDateInPast = false;
			} else {
				let dateTime = splitDateTime(action.valueStartDateTime);
				let convertedDateTime = moment.utc(dateTime.date + " " + dateTime.time).local();
				newState.createModel.startDate = convertedDateTime.format("YYYY-MM-DD");
				newState.createModel.startTime = convertedDateTime.format("HH:mm");
				let now = moment.utc(moment.now()).unix();
				newState.savedStartDateInPast = convertedDateTime.unix() <= now ? true : false;
			}
			newState.createModel.maxLimitedNumber = action.valueLimitedNumber;

			if (!action.valueStartOfSaleDateTime) {
				newState.createModel.startOfSaleDate = "";
				newState.createModel.startOfSaleTime = "";
				newState.savedStartOfSaleDateInPast = false;
			} else {
				let dateTime = splitDateTime(action.valueStartOfSaleDateTime);
				let convertedDateTime = moment.utc(dateTime.date + " " + dateTime.time).local();
				newState.createModel.startOfSaleDate = convertedDateTime.format("YYYY-MM-DD");
				newState.createModel.startOfSaleTime = convertedDateTime.format("HH:mm");
				let now = moment.utc(moment.now()).unix();
				newState.savedStartOfSaleDateInPast = convertedDateTime.unix() <= now ? true : false;
			}
			newState.updateView = true;

			break;
		case "SHOW_BANNER":
			newState.isBannerShow = action.isBannerShow;
			newState.bannerTitle = action.title;
			newState.bannerDesc = action.desc;
			newState.bannerState = action.state;
			break;
	}

	return newState;
}
