import React, { ReactElement } from "react";
import './customTable.scss';

interface ICustomTable {
	tableId: string;
	children: ReactElement[];
	width?: string
	className?: string;
}

export function CustomTable(props: ICustomTable): ReactElement {

	let className = props.className? "custom_table " + props.className : "custom_table"
	return (
		<table className={className} id={props.tableId} style={{width: props.width}}>
			{props.children}
		</table>
	);
}