import React, { ChangeEvent, useContext, useState } from "react";
import UserContext from "../../store/UserContext";
import {
	BannerState,
	PBanner,
	PButton, PContentWrapper,
	PDivider,
	PGrid,
	PGridItem, PHeadline, PSpinner,
	PText
} from "@porsche-design-system/components-react";
import { uploadVipReservations } from "../../api/LimitationNumberService";
import { useModelState } from "../../store/ModelContext";
import { UploadResponse } from "../../store/response";

interface Banner {
	state: BannerState;
	title: string;
	description: string;
	duration?: number;
}

function VipReservationTab(props) {
	const { lenaAuthToken } = useContext(UserContext);
	const modelContextState = useModelState();
	const [fileName, setFilename] = useState<string>("");
	const [uploadResult, setUploadResult] = useState<Array<UploadResponse>>([])
	const [isBannerShow, setBannerShow] = useState(false);
	const [bannerInfo, setBannerInfo] = useState<Banner>({
		state: "neutral",
		title: "LENA Notification",
		description: "",
		duration: 3000
	});
	const [showSubmitFileUploadBanner, setShowSubmitFileUploadBanner] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	function showBanner(message, resultState) {
		let bannerInfo: Banner = {
			state: resultState,
			title: "LENA Notification",
			description: message,
			duration: 6000
		};
		setBannerInfo(bannerInfo);
		setBannerShow(true);

		const timer = setTimeout(() => {
			setBannerShow(false);
		}, bannerInfo.duration);
		return () => clearTimeout(timer);
	}

	function submitFile() {
		setShowSubmitFileUploadBanner(false);
		setLoading(true);
		const input: HTMLInputElement = document.querySelector('input[type="file"]');
		let reader = new FileReader();
		reader.readAsDataURL(input.files[0]);
		reader.onload = function () {
			uploadVipReservations(lenaAuthToken, reader.result, fileName, modelContextState.globalOrderType)
				.then((response) => {
					setFilename("");
					setLoading(false);
					setUploadResult(response);
					input.value = "";

					if(response.length === 0){
						showBanner("All reservations are saved successfully.", "neutral");
					}
				})
				.catch((error) => {
					showBanner(error.message, "error");
					setLoading(false);
				});
		};
		reader.onerror = function (error : ProgressEvent<FileReader>) {
			console.log(error);
			showBanner("Error during file upload: " + error, "error");
		};
	}

	function handleSubmitFileUpload() {
		if(modelContextState.globalOrderType === ""){
			const input: HTMLInputElement = document.querySelector('input[type="file"]');
			showBanner("Please select a model.", "error");
			input.value = "";
		}else {
			setShowSubmitFileUploadBanner(false);
			setTimeout(() => {
				setShowSubmitFileUploadBanner(true);
			}, 100);
		}
	}

	function handleCancelSubmitFileUpload() {
		setShowSubmitFileUploadBanner(false);
		setFilename("");
		const input: HTMLInputElement = document.querySelector('input[type="file"]');
		input.value = "";
	}

	function uploadFile(e: ChangeEvent<HTMLInputElement>) {
		const fileName = e.target.files[0].name;
		if (fileName.indexOf(".csv") > 0) {
			setFilename(fileName);
		} else {
			showBanner("The uploaded file is not a csv file.", "error");
		}
	}

	return (
		<PContentWrapper width={"extended"}>
			{isBannerShow && (
				<PBanner width="basic" persistent={true} state={bannerInfo.state} className="info-banner">
					<span slot="title">{bannerInfo.title}</span>
					<span slot="description">{bannerInfo.description}</span>
				</PBanner>
			)}
			{showSubmitFileUploadBanner && (
				<PBanner
					width="basic"
					persistent={false}
					onDismiss={() => handleCancelSubmitFileUpload()}
					className="submit-file-upload-confirm-banner">
					<span slot="title" className="submit-file-upload-confirm-banner__title">
						{"After the upload existing reservations will be lost or overwritten by new one. Do you want to continue?"}
					</span>
					<div slot="description" className="submit-file-upload-confirm-banner__description">
						<PButton variant="primary" className="confirm-button" onClick={() => submitFile()}>
							Ok
						</PButton>
						<PButton className="confirm-button" onClick={() => handleCancelSubmitFileUpload()}>
							Cancel
						</PButton>
					</div>
				</PBanner>
			)}
			<PGrid direction={"column"} className={"upload-grid"}>
				<PGridItem size={11}>
					<input
						type="file"
						className={"inputFileUpload"}
						id="inputFileUpload"
						onChange={(e) => {
							uploadFile(e);
						}}
					/>
					{fileName !== "" && <PText>Uploaded file: {fileName}</PText>}
				</PGridItem>
				<PGridItem offset={11} size={1} >
					<PButton variant={"primary"} onClick={() => handleSubmitFileUpload()}>
						Submit
					</PButton>
				</PGridItem>
				<PGridItem size={12}>
					{loading && <PSpinner className={"spinner"} size={{ base: "small", l: "medium" }} />}
					{ !loading && uploadResult.length > 0 && <div className={"message-field-wrapper"}>
						<PDivider className="divider"></PDivider>
						<PHeadline className={"headline"} variant="headline-5">
							Upload Messages
						</PHeadline>
						<div className={"message-field"}>
							{uploadResult.map((result, index) => {
									return (<PText className={"message-text"} key={index + "upload"}>{result.message}</PText>)
								}
							)}
						</div>
					</div>
					}
				</PGridItem>
			</PGrid>
		</PContentWrapper>
	);
}
export default VipReservationTab;
