import React, {ReactElement , useState} from 'react'

interface ControlProps {
	roles : Array<string>;
	partnerNumber : string;
	lenaAuthToken: string;
	isDealer: Boolean;
	children: React.ReactNode;
}

export const UserContext = React.createContext({
	roles : [],
	partnerNumber : "",
	lenaAuthToken : "",
	isDealer: undefined,
})

export const UserProvider =  (props: ControlProps): ReactElement => {

	const [roles] = useState(props.roles);
	const [partnerNumber] = useState(props.partnerNumber);
	const [lenaAuthToken] = useState(props.lenaAuthToken);
	const [isDealer] = useState(props.isDealer);

	const value = {
		roles: roles,
		partnerNumber: partnerNumber,
		lenaAuthToken: lenaAuthToken,
		isDealer: isDealer
	};

	return (
		<UserContext.Provider value={value}>
			{props.children}
		</UserContext.Provider>
	);
}
export default UserContext