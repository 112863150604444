import React from 'react'
import './customLabel.scss';
import classnames from 'classnames';

export interface ICustomLabelProps {
  text: string;
  position: "top-left" | "top-right" | "bottom-left" | "bottom-right";
}

const CustomLabel = (props: ICustomLabelProps) => {
  return(
    <div className={classnames('custom_label', `custom_label--${props.position}`)}>
        {props.text}
    </div>
  )
};

export default CustomLabel;
