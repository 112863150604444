import moment from "moment";

export function showDateTimeAsString(dateTimeJson: any) {
	let dateTime = splitDateTime(dateTimeJson);

	let dateTimeAsString = dateTimeJson !== null
		? moment.utc(dateTime.date + " " + dateTime.time).local().format("YYYY.MM.DD HH:mm")
		: "";

	return dateTimeAsString;
}

export function showDateTimeAsStringWithSeconds(dateTimeJson: any) {
	let dateTime = splitDateTime(dateTimeJson);

	let dateTimeAsString = dateTimeJson !== null
		? moment.utc(dateTime.date + " " + dateTime.time).local().format("YYYY.MM.DD HH:mm:ss")
		: "";

	return dateTimeAsString;
}
export function showDateTimeAsStringWithMilliseconds(dateTimeJson: any) {
	let dateTime = splitDateTime(dateTimeJson);

	let dateTimeAsString = dateTimeJson !== null
		? moment.utc(dateTime.date + " " + dateTime.time).local().format("YYYY.MM.DD HH:mm:ss.SSS")
		: "";

	return dateTimeAsString;
}
export function splitDateTime(dateTime: any) {
	let date = "";
	let time = "";
	if (dateTime) {
		let dateTimeArray = dateTime.split(" ", 2);
		date = dateTimeArray[0];
		time = dateTimeArray[1];
	}
	return { date: date, time: time };
}

export  function getDateTimeAsString(date: string, time: string){
	let dateTimeAsString = moment(new Date(date + "T" + time))
	.utc()
	.toISOString(false)
		? moment(new Date(date + "T" + time))
		.utc()
		.format("YYYY-MM-DD HH:mm:00")
		: "";
	return dateTimeAsString;
}