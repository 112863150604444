import './textField.scss';

import React, { useState, useRef, useEffect } from 'react';
import { PTextFieldWrapper } from "@porsche-design-system/components-react";
import CustomFieldWrapper from '../customFieldWrapper/customFieldWrapper';

export interface ITextFieldProps {
  placeholder: string;
  value?: string,
  className?: string,
  onChange?: (value: string) => void;
  onConfirm?: (value: string) => void;
  clearable?: boolean;
  disabled?: boolean;
  labels?: [React.ReactNode];
  showTooltip?: boolean;
  tooltip?: string;
  maskBeforeEdit?: boolean;
  confirmOnFocusOut?: boolean;
  multiline?: boolean;
  rows?: number;
  isError?: boolean;
  errorMsg?: string;
  isFocus?: boolean;
  id? : string;
}

const TextField = (props: ITextFieldProps) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState("");
  const [focused, setFocused] = useState(props.isFocus)
  const fieldValue = props.value !== undefined ? props.value : value

  useEffect(() => {
    if (props.isFocus === true) inputRef.current.focus()
  }, [props.isFocus]);

  return (
    <PTextFieldWrapper
      state={props.isError === true ? "error" : 'none'}>
      <CustomFieldWrapper
        className={props.className}
        clearable={props.clearable}
        clearAction={() => {
          setValue('');
          props.onConfirm && props.onConfirm('');
        }}
        showClear={!!fieldValue}
        placeholder={props.placeholder}
        hasValue={!!fieldValue || focused}
        disabled={props.disabled}
        labels={props.labels}
        showTooltip={props.showTooltip}
        tooltip={props.tooltip}
        maskBeforeEdit={props.maskBeforeEdit}
        fieldValue={fieldValue}
        multiline={props.multiline}
      >
        {
          props.multiline ? (
            <textarea
              ref={inputRef}
              value={fieldValue}
              rows={props.rows}
              className={props.isError === false ? "text-field-input--multiline" : "text-field-input--multiline error-input"}
              disabled={props.disabled}
              onFocus={() => { setFocused(true); }}
              onBlur={() => {
                setFocused(false);
                props.confirmOnFocusOut && props.onConfirm && props.onConfirm(value);
              }}
              onChange={(e) => {
                const inputValue = e.target.value;
                props.onChange && props.onChange(inputValue);
                setValue(inputValue);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  props.onConfirm && props.onConfirm(value);
                }
              }}
            />
          ) : (
              <input
                ref={inputRef}
                id={props.id}
                value={fieldValue}
                className={props.isError === false ? "text-field-input" : "ext-field-input error-input"}
                type="text"
                disabled={props.disabled}
                onFocus={() => { setFocused(true); }}
                onBlur={() => {
                  setFocused(false);
                  props.confirmOnFocusOut && props.onConfirm && props.onConfirm(value);
                }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  props.onChange && props.onChange(inputValue);
                  setValue(inputValue);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    props.onConfirm && props.onConfirm(value);
                  }
                }}
              />
            )
        }
      </CustomFieldWrapper>
      <span slot="message" id={"error-message-"+props.id} className="validation-message">{props.errorMsg}</span>
    </PTextFieldWrapper>

  )
};

TextField.defaultProps = {
  disabled: false,
  clearable: false,
  showTooltip: false,
  maskBeforeEdit: false,
  multiline: false,
};

export default TextField;
