import './resetButton.scss';

import React from 'react';
import classnames from 'classnames';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

export interface IResetButtonProps {
  show: boolean;
  action: () => void;
  className?: string;
}

const ResetButton = (props: IResetButtonProps) => {
  return(
    <div className={classnames('clear_container', props.className, props.show ? '' : 'prevent-focus')}>
        {
            props.show && (
            <div
                className={'clear_container__clear-button'}
                onClick={props.action}
            >
                <CloseIcon width={16} height={16}/>
            </div> 
            )
        }
    </div>
  )
};

export default ResetButton;