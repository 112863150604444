import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/de";

import {
	BannerState,
	PBanner,
	PButton,
	PButtonPure,
	PDivider,
	PGrid,
	PGridItem,
	PHeadline,
	PSpinner,
	PText
} from "@porsche-design-system/components-react";
import { CreateModelState } from "../../store/types";
import * as actions from "../../store/actions";
import { lenaReducer } from "../../store/reducers";
import TextField from "../../components/textField/textField";
import CustomLabel from "../../components/customLabel/customLabel";
import Datepicker from "../../components/datepicker/datepicker";
import { CustomTable } from "../../components/customTable/customTable";
import { CustomTableHeader } from "../../components/customTable/customTableHeader";
import { deleteModel, getModels, saveModel, updateModel } from "../../api/ModelService";
import { getDateTimeAsString, showDateTimeAsString, splitDateTime } from "../../utils";
import UserContext from "../../store/UserContext";
import { MarketDependentSos } from "./MarketDependentSos";
import { ImporterSos, SosDateImporters } from "../../store/importerSos";
import { getImportersSosByOrderType } from "../../api/ModelImporterService";
import { useModelDispatch } from "../../store/ModelContext";
import { SaveModel, UpdateModel } from "../../store/model";

type ModelBanner = {
	state: BannerState;
	title: string;
	description: string;
	duration: number;
};

function MaintainTab(props) {
	//create maintain model state
	const initialState: CreateModelState = {
		createModel: {
			orderType: "",
			description: "",
			startDate: "",
			startTime: "",
			maxLimitedNumber: "",
			startOfSaleDate: "2030-01-01",
			startOfSaleTime: "00:00",
			importerStartOfSaleDateTime: []
		},
		createModelValid: {
			orderTypeValid: true,
			descriptionValid: true,
			startDateValid: true,
			startTimeValid: true,
			maxLimitedNumberValid: true,
			startOfSaleDateValid: true,
			startOfSaleTimeValid: true
		},
		triggerSave: false,
		triggerUpdate: false,
		triggerUpdateList: false,
		updateView: false,
		isBannerShow: false,
		bannerTitle: "",
		bannerDesc: "",
		bannerState: "",
		savedStartDateInPast: false,
		savedStartOfSaleDateInPast: false
	};
	const { lenaAuthToken } = useContext(UserContext);
	const [state, dispatch] = React.useReducer(lenaReducer, initialState);
	// context definition
	// @ts-ignore

	const [models, setModels] = useState<Model[]>([]);
	const [isBannerShow, handleBannerShow] = useState(false);
	const initialBanner: ModelBanner = {
		state: "neutral",
		title: "",
		description: "",
		duration: 6000
	};
	const [bannerInfo, handleBannerInfo] = useState(initialBanner);
	const [isDeleteBanner, handleDeleteBannerShow] = useState(false);
	const [delOrderType, selectDeleteModel] = useState(null);
	const [orderTypeFocus, handleOrderTypeFocus] = useState(false);
	const [loading, handlePageLoading] = useState(false);
	const tableId = "modelTable";
	const [marketDependentSos, setMarketDependentSos] = useState<SosDateImporters[]>([]);
	const [modelImporterRequestList, setModelImporterRequestList] = useState<ImporterSos[]>([]);
	const modelContextDispatch = useModelDispatch();

	const onChangeOrderType = (value: string) => {
		dispatch(actions.changeOrderType(value));
	};

	const onChangeDescription = (value: string) => {
		dispatch(actions.changeDescription(value));
	};

	const onChangeStartDate = (value: string) => {
		let validFormat = value ? value.split(".").join("-") : null;
		dispatch(actions.changeStartDate(validFormat));
	};

	const onChangeStartTime = (value: string) => {
		dispatch(actions.changeStartTime(value));
	};

	const onChangeStartOfSaleDate = (value: string) => {
		let validFormat = value ? value.split(".").join("-") : null;
		dispatch(actions.changeStartOfSaleDate(validFormat));
	};

	const onChangeStartOfSaleTime = (value: string) => {
		dispatch(actions.changeStartOfSaleTime(value));
	};

	const onChangeMaxLimitedNumber = (value: string) => {
		dispatch(actions.changeMaxLimitedNumber(value));
	};

	const onSubmit = () => {
		let modelImporterRequestList = [];
		marketDependentSos.forEach((m) => {
			modelImporterRequestList = modelImporterRequestList.concat(m.ImporterSos);
		});
		setModelImporterRequestList(modelImporterRequestList);
		dispatch(actions.doSubmit());
	};

	const doReset = () => {
		setMarketDependentSos([]);
		dispatch(actions.doReset());
	};

	const doDelete = () => {
		dispatch(actions.doDelete());
	};

	const showBanner = (message, resultState) => {
		let bannerInfo: ModelBanner = {
			state: resultState,
			title: "LENA Notification",
			description: message,
			duration: 6000
		};
		handleBannerInfo(bannerInfo);
		handleBannerShow(true);
		dispatch(actions.showBannerMsg(true, "LENA", message, resultState));
		setTimeout(() => {
			handleBannerShow(false);
		}, bannerInfo.duration);
	};

	useEffect(doDelete, []);

	useEffect(() => {
		//Save Model
		if (state.triggerSave) {
			handlePageLoading(true);
			let request: SaveModel = {
				orderType: state.createModel.orderType,
				description: state.createModel.description,
				startDateTime: getDateTimeAsString(state.createModel.startDate, state.createModel.startTime),
				startOfSaleDateTime: getDateTimeAsString(state.createModel.startOfSaleDate, state.createModel.startOfSaleTime),
				maxLimitedNumber: parseInt(state.createModel.maxLimitedNumber),
				modelImporterRequestList: modelImporterRequestList
			};
			saveModel(lenaAuthToken, request)
				.then((result) => {
					doReset();
					doDelete();
					// engl: Please communicate the starting time through all relevant channels to inform the importers.
					showBanner(result + " Please communicate start date and time accordingly to importers via relevant information channels.", "neutral");

				})
			.catch((error) => {
				handlePageLoading(false);
				modelContextDispatch({ type: "UPDATE_MODEL_LIST", doUpdate: true });
				showBanner(error.message, "error");
			}).finally(() => {
				handlePageLoading(false);
				modelContextDispatch({ type: "UPDATE_MODEL_LIST", doUpdate: true });
			});
		}
		//Update Model
		if (state.triggerUpdate) {
			handlePageLoading(true);
			let request : UpdateModel = {
				orderType: state.createModel.orderType,
				description: state.createModel.description,
				startDateTime: getDateTimeAsString(state.createModel.startDate, state.createModel.startTime),
				startOfSaleDateTime: getDateTimeAsString(state.createModel.startOfSaleDate, state.createModel.startOfSaleTime),
				modelImporterRequestList: modelImporterRequestList
			};
			updateModel(lenaAuthToken, request)
				.then((result) => {
					doReset();
					doDelete();
					// engl: Please communicate the starting time through all relevant channels to inform the importers.
					showBanner(result + " Please communicate start date and time accordingly to importers via relevant information channels.", "neutral");
				})
				.catch((error) => {
					showBanner(error.message, "error");
					handlePageLoading(false);
					modelContextDispatch({ type: "UPDATE_MODEL_LIST", doUpdate: true });
				}).finally(() => {
					handlePageLoading(false);
					modelContextDispatch({ type: "UPDATE_MODEL_LIST", doUpdate: true });
			});
		}
	}, [state, lenaAuthToken, modelImporterRequestList, modelContextDispatch]);

	useEffect(() => {
		if (state.triggerUpdateList || props.triggerUpdate) {
			setModels([]);
			getModels(lenaAuthToken)
				.then((models) => {
					setModels(models);
				})
				.catch((error) => {
					showBanner(error.message, "error");
				});
		}
	}, [state, lenaAuthToken, props.triggerUpdate, loading]);

	const [importerSosList, setImporterSosList] = useState<ImporterSos[]>([]);

	useEffect(() => {
		let orderType = state.createModel.orderType === "" ? null : state.createModel.orderType;
		if(orderType === null  || orderType.length === 5){
			getImportersSosByOrderType(lenaAuthToken, orderType)
			.then((importerSos: ImporterSos[]) => {
				setImporterSosList(importerSos);
				if (state.updateView) {
					let sosList = [];
					state.createModel.importerStartOfSaleDateTime.forEach((sos) => {
						let importer = importerSos.filter((importer) => importer.startOfSaleDateTime === sos);
						let importerSosItem = {
							startOfSaleDateTime: sos,
							ImporterSos: importer
						};
						sosList.push(importerSosItem);
					});
					setMarketDependentSos(sosList);
				}else{
				setMarketDependentSos([]);
			}
			})
			.catch((error) => {
				showBanner(error.message, "error");
			});
		}
	}, [lenaAuthToken, state.createModel.orderType, state.createModel.importerStartOfSaleDateTime, state.updateView]);

	function HandleUpdateModel(orderType: string) {
		getModels(lenaAuthToken).then((result) => {
			const selOrder = result.find((s) => s.orderType === orderType);
			setModels(result);
			handleOrderTypeFocus(false);
			dispatch(
				actions.switchToUpdateModel(
					selOrder.orderType,
					selOrder.description,
					selOrder.startDateTime,
					selOrder.maxLimitedNumber,
					selOrder.startOfSaleDateTime,
					selOrder.importerStartOfSaleDateTime
				)
			);
			setTimeout(() => {
				handleOrderTypeFocus(true);
			}, 100);
		});
	}

	function HandleDelete(orderType: string) {
		handleDeleteBannerShow(false);
		selectDeleteModel(orderType);
		setTimeout(() => {
			handleDeleteBannerShow(true);
		}, 100);
	}

	function handleDeleteConfirm() {
		if (delOrderType === null) return;
		handleDeleteBannerShow(false);
		handlePageLoading(true);
		deleteModel(lenaAuthToken, delOrderType)
		.then((result) => {
			selectDeleteModel(null);
			doDelete();
			showBanner(result, "neutral");
		})
		.catch((error) => {
			handlePageLoading(false);
			modelContextDispatch({ type: "UPDATE_MODEL_LIST", doUpdate: true });
			showBanner(error.message, "error");
		}).finally(() => {
			handlePageLoading(false);
			modelContextDispatch({ type: "UPDATE_MODEL_LIST", doUpdate: true });
		});
	}

	function handleDeleteCancel() {
		selectDeleteModel(null);
		handleDeleteBannerShow(false);
	}

	function validateSavedStartDateWithNow(dateTimeJson: any) {
		let locale = navigator.language;
		moment.locale(locale);
		let dateTime = splitDateTime(dateTimeJson);
		return moment.utc(moment.now()).unix() > moment.utc(new Date(dateTime.date + " " + dateTime.time)).unix();
	}

	/**
	 * adding new MarketDependentSos-Components
	 */
	function addMarketDependentSos() {
		let sos = [
			...marketDependentSos,
			{
				startOfSaleDateTime: "",
				ImporterSos: []
			}
		];
		setMarketDependentSos(sos);
	}

	function updateMarketDependentSosList(index: number, startOfSaleDateTime: string, importerSos: ImporterSos[]) {

		marketDependentSos[index].startOfSaleDateTime = startOfSaleDateTime;
		let currentImporterSos = marketDependentSos[index].ImporterSos;
		let importerSosToDelete = [];

		//find all importerSos where startOfSaleDateTime had to be set empty

		currentImporterSos.forEach( item => {
			if(! importerSos.find(i => i.importer === item.importer)){
				importerSosToDelete.push(item);
			}
		})
		marketDependentSos[index].ImporterSos = importerSos;

		let importerSosListTemp = [...importerSosList];
		importerSos.forEach((item) => {
			let importerSos = importerSosListTemp.find((importerSos) => importerSos.importer === item.importer);
			importerSos.startOfSaleDateTime = item.startOfSaleDateTime;
		});

		//set startOfSaleDateTime empty for all importerSos in importerSosToDelete array
		importerSosToDelete.forEach((item) => {
			let importerSos = importerSosListTemp.find((importerSos) => importerSos.importer === item.importer);
			importerSos.startOfSaleDateTime = null;
		});
		setImporterSosList(importerSosListTemp);
	}


	return (
		<div className="wrapper">
			{isBannerShow && (
				<PBanner width="basic" state={bannerInfo.state} persistent={true} className="create-model__banner_position">
					<span slot="title">{bannerInfo.title}</span>
					<span slot="description">{bannerInfo.description}</span>
				</PBanner>
			)}
			{isDeleteBanner && (
				<PBanner
					width="basic"
					persistent={false}
					onDismiss={() => handleDeleteCancel()}
					className="create-model__banner_position delete-confirm-banner">
					<span slot="title" className="delete-confirm-banner__title">
						{"Do you really want to delete model " + delOrderType + "?"}
					</span>
					<div slot="description" className="delete-confirm-banner__description">
						<PButton variant="primary" className="confirm-button" onClick={() => handleDeleteConfirm()}>
							Ok
						</PButton>
						<PButton className="confirm-button" onClick={() => handleDeleteCancel()}>
							Cancel
						</PButton>
					</div>
				</PBanner>
			)}
			<div className={"create-model__input_container"}>
				<div className={"create-model__input_container__subsection"}>
					<PHeadline  variant="headline-5">
						{state.updateView ? "Edit limited edition" : "Create new limited edition"}
					</PHeadline>
					<div className={"row"}>
						<PGrid>
							<PGridItem size={6}>
								<TextField
									placeholder={"Please type in 5 digit model code*"}
									labels={[<CustomLabel key="labe" position={"bottom-right"} text="Model" />]}
									onChange={(value) => onChangeOrderType(value)}
									isError={!state.createModelValid.orderTypeValid}
									errorMsg="Model has to be 5 digit code."
									value={state.createModel.orderType}
									disabled={state.updateView}
									isFocus={orderTypeFocus && !state.updateView}
									className={"create-model__input_section__field"}
								/>
							</PGridItem>
							<PGridItem size={6}>
								<TextField
									placeholder={"Please type in model description*"}
									labels={[<CustomLabel key="labe" position={"bottom-right"} text="Model description" />]}
									onChange={(value) => onChangeDescription(value)}
									isError={!state.createModelValid.descriptionValid}
									errorMsg="Model description is a mandatory field."
									value={state.createModel.description}
									isFocus={orderTypeFocus && state.updateView}
									className={"create-model__input_section__field"}
								/>
							</PGridItem>
							<PGridItem size={6}>
								<div className={"create-model-details__input_container__subsection flex-row"}>
									<div className="flex-3">
										<Datepicker
											className={"create-model-details__input_section__field"}
											placeholder={"Please type in a start date"}
											value={state.createModel.startDate}
											onChange={(value) => onChangeStartDate(value)}
											isError={!state.createModelValid.startDateValid}
											errorMsg="Date is a mandatory field."
											dateFormat={"yyyy.MM.dd"}
											labels={[<CustomLabel key="labe" position={"bottom-right"} text="Start date" />]}
											showTooltip={false}
											tooltip={"Please communicate start date and time to importers via relevant information channels. "}
											disabled={state.updateView && state.savedStartDateInPast}
										/>
									</div>
									<PText className={"custom-field create-model-details__input_section__field"}>
										<div className="custom-field__wrapper">
											<input
												className={
													state.createModelValid.startTimeValid === true
														? "timepicker-box text-field-input"
														: "timepicker-box text-field-input error-input"
												}
												name="startTime"
												onChange={(event) => onChangeStartTime(event.target.value)}
												value={state.createModel.startTime}
												type={"time"}
												disabled={state.updateView && state.savedStartDateInPast}
											/>
										</div>
									</PText>
								</div>
							</PGridItem>
							<PGridItem size={6}>
								<TextField
									className={"create-model-details__input_section__field"}
									placeholder={"Please type in limitation*"}
									labels={[<CustomLabel key="labe" position={"bottom-right"} text="Limitation" />]}
									onChange={(value) => onChangeMaxLimitedNumber(value)}
									isError={!state.createModelValid.maxLimitedNumberValid}
									errorMsg="Limitation has to be a positiv natural number."
									value={state.createModel.maxLimitedNumber}
									disabled={state.updateView}
								/>
							</PGridItem>
							<PGridItem size={6}>
								<div className={"create-model-details__input_container__subsection flex-row"}>
									<div className="flex-3">
										<Datepicker
											className={"create-model-details__input_section__field"}
											placeholder={"Please type in SOS date"}
											value={state.createModel.startOfSaleDate}
											onChange={(value) => onChangeStartOfSaleDate(value)}
											isError={!state.createModelValid.startOfSaleDateValid}
											errorMsg="Date is a mandatory field."
											dateFormat={"yyyy.MM.dd"}
											labels={[<CustomLabel key="labe" position={"bottom-right"} text="SOS date" />]}
											showTooltip={false}
											tooltip={"Please communicate SOS and time to importers via relevant information channels. "}
											disabled={state.updateView && state.savedStartOfSaleDateInPast}
										/>
									</div>
									<PText className={"custom-field create-model-details__input_section__field"}>
										<div className="custom-field__wrapper">
											<input
												className={
													state.createModelValid.startOfSaleTimeValid === true
														? "timepicker-box text-field-input"
														: "timepicker-box text-field-input error-input"
												}
												name="startOfSaleTime"
												onChange={(event) => onChangeStartOfSaleTime(event.target.value)}
												value={state.createModel.startOfSaleTime}
												type={"time"}
												disabled={state.updateView && state.savedStartOfSaleDateInPast}
											/>
										</div>
									</PText>
								</div>
							</PGridItem>
							<PGridItem size={6}></PGridItem>
							{importerSosList.length > 0 &&
								marketDependentSos.map((item, index) => {
									return (
										<PGridItem  id={"marketDependentSosGridItem-"+index} key={"gridItem-" + index} size={12} className={"market-dependent-sos-grid-item"}>
											<MarketDependentSos
												orderType={state.createModel.orderType}
												marketDependentSosItem={item}
												itemIndex={index}
												updateView={state.updateView}
												totalMarketDependentSos={marketDependentSos}
												addToMarketDependentSosList={updateMarketDependentSosList}
												importersSosList={importerSosList}></MarketDependentSos>
										</PGridItem>
									);
								})}
							{importerSosList.length > 0 && (
								<PGridItem size={3} className={"add-sos-grid-item"}>
									<PButtonPure icon={"add"} size={"small"} className={"create-model-details_addMore"} onClick={addMarketDependentSos}>
										Add more SOS
									</PButtonPure>
								</PGridItem>
							)}
							<PGridItem size={12} className="submit-button">
								<PButton className="button cancel-button" onClick={doReset} disabled={loading}>
									Cancel
								</PButton>
								<PButton className="button" variant={"primary"} onClick={onSubmit} loading={loading} disabled={loading}>
									Save
								</PButton>
							</PGridItem>
						</PGrid>
					</div>
				</div>
			</div>
			<PDivider className="divider"></PDivider>
			<PHeadline className={"headline-modelluebersicht"} variant="headline-5">
				Model overview
			</PHeadline>

			{loading && <PSpinner size={{ base: "small", l: "medium" }} />}
			{!loading && (
				<CustomTable tableId={tableId}>
					<thead>
						<tr>
							<CustomTableHeader text={"Model"} tableId={tableId} columnPos={0} sortable={false} />
							<CustomTableHeader text={"Model description"} tableId={tableId} columnPos={1} sortable={false} />
							<CustomTableHeader text={"Start date"} tableId={tableId} columnPos={2} sortable={false}  isDate={true} />
							<CustomTableHeader text={"SOS date"} tableId={tableId} columnPos={3} sortable={false}  isDate={true} />
							<CustomTableHeader text={"Limitation"} tableId={tableId} columnPos={4} sortable={false} />
							<CustomTableHeader text={""} tableId={tableId} columnPos={5} sortable={false} />
							<CustomTableHeader text={""} tableId={tableId} columnPos={6} sortable={false} />
						</tr>
					</thead>
					<tbody>
						{Array.from(models).map((model) => (
							<tr key={model.orderType}>
								<td>{model.orderType}</td>
								<td>{model.description}</td>
								<td>{showDateTimeAsString(model.startDateTime)}</td>
								<td>{showDateTimeAsString(model.startOfSaleDateTime)}</td>
								<td>{model.maxLimitedNumber}</td>
								<td>
									<PButton variant="primary" onClick={() => HandleUpdateModel(model.orderType)}>
										Change
									</PButton>
								</td>
								<td>
									<PButton disabled={validateSavedStartDateWithNow(model.startDateTime)} onClick={() => HandleDelete(model.orderType)}>
										Delete
									</PButton>
								</td>
							</tr>
						))}
					</tbody>
				</CustomTable>
			)}
		</div>
	);
}
export default MaintainTab;
