import React from "react";
import ReactDOM from "react-dom";
import { PorscheDesignSystemProvider } from "@porsche-design-system/components-react";

import App from "./App";
import { ModelStateProvider } from "./store/ModelContext";

ReactDOM.render(
	<PorscheDesignSystemProvider prefix={"lena"}>
		<ModelStateProvider>
			<App />
		</ModelStateProvider>
	</PorscheDesignSystemProvider>,
	document.getElementById("root")
);
