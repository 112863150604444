import React, { ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { BannerState, PBanner, PButton, PContentWrapper, PFlex, PSpinner, PText } from "@porsche-design-system/components-react";
import { getLimitationNumbersByOrderType, saveLimitationNumber, saveLimitationNumbers } from "../../api/LimitationNumberService";
import { LimitationNumber, SaveLimitationNumberRequest, State } from "../../store/limitationNumber";
import { Timer } from "./Timer";
import UserContext from "../../store/UserContext";
import { getQuoteByOrderTypeAndPartnerNumber } from "../../api/ModelImporterQuoteService";
import { QuotaInformation } from "./QuotaInformation";
import { useModelState } from "../../store/ModelContext";
import { SearchFields } from "./SearchFields";

interface Banner {
	state: BannerState;
	title: string;
	description: string;
	duration?: number;
}

const EXPIRATION_TIME = 901;

export function SearchTab(props): ReactElement {

	const { partnerNumber, lenaAuthToken, isDealer } = useContext(UserContext);
	const modelContextState = useModelState();
	const [limitationNumbers, setLimitationNumbers] = useState<Array<LimitationNumber>>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedLimitationNumbers, setSelectedLimitationNumbers] = useState<Array<LimitationNumber>>([]);
	const [startTimer, setStartTimer] = useState<boolean>(false);
	const [timer, setTimer] = useState<number>(EXPIRATION_TIME);
	const [isBannerShow, setBannerShow] = useState(false);
	const [bannerInfo, setBannerInfo] = useState<Banner>({
		state: "neutral",
		title: "LENA Notification",
		description: "",
		duration: 6000
	});
	const  [searchedLimitedNumber, setSearchedLimitedNumber] = useState<string>("");
	const  [excludedNumber, setExcludedNumber] = useState<string>("");
	const  [includedNumber, setIncludedNumber] = useState<string>("");
	const [displayedLimitationNumbers, setDisplayedLimitationNumbers] = useState<Array<LimitationNumber>>([]);
	const [overAllQuote, setOverAllQuote] = useState<number>();
	const [assignedQuotes, setAssignedQuotes] = useState<number>();
	const [ordersByChanceCount, setOrdersByChanceCount] = useState<number>();
	const [searchOption, setSearchOption] = useState<string>("1");


	const loadLimitationNumbers = useCallback(() => {
		if (modelContextState.globalOrderType !== "") {
			setSearchedLimitedNumber("");

			getLimitationNumbersByOrderType(lenaAuthToken, modelContextState.globalOrderType).then((reservations) => {
				setLimitationNumbers(reservations);
				setDisplayedLimitationNumbers(reservations);
				setSelectedLimitationNumbers([]);
				let count = reservations.filter(
					(reservation) =>
						reservation.limitedNumber > 0 &&
						(reservation.reservationState === State.SELECTED ||
							reservation.reservationState === State.RESERVED ||
							reservation.reservationState === State.BOOKED) &&
						((!isDealer && reservation.importer === partnerNumber) || (isDealer && reservation.dealerNumber === partnerNumber))
				).length;
				setAssignedQuotes(count);

				let countOrdersByChance = reservations.filter(
					(reservation) =>
						reservation.reservationState === State.BOOKED &&
						reservation.importer === partnerNumber &&
						reservation.limitedNumber === 0
				).length;
				setOrdersByChanceCount(countOrdersByChance);
			}).then(() => {
				getQuoteByOrderTypeAndPartnerNumber(lenaAuthToken, modelContextState.globalOrderType, partnerNumber).then(quote => {
					setOverAllQuote(quote)
				}).catch(error => {
					showBanner(error.message, "error");
				});
			}).then(() => {
				setStartTimer(false);
				setLoading(false);
			});
			setLoading(true);
		}
	},[modelContextState.globalOrderType, partnerNumber, lenaAuthToken, isDealer])

	/**
	 * Load limitation numbers
	 */
	useEffect(() => {
		loadLimitationNumbers();
	}, [modelContextState.globalOrderType, partnerNumber, loadLimitationNumbers, props.triggerUpdate])
	

	function showBanner(message, resultState) {
		let bannerInfo: Banner = {
			state: resultState,
			title: "LENA Notification",
			description: message,
			duration: 6000
		};

		setBannerInfo(bannerInfo);
		setBannerShow(true);

		const timer = setTimeout(() => {
			setBannerShow(false);
		}, bannerInfo.duration);
		return () => clearTimeout(timer);
	}
	
	const loadLimitationNumber = useCallback(
		(searchedNumber: any) => {
			if (modelContextState.globalOrderType !== "") {
				let filteredLimitedNumbers: LimitationNumber[] = [...limitationNumbers];
				switch (searchOption) {
					case "1":
					case undefined:
						//exactly searched number
						if (typeof searchedNumber === 'string') {
							let searchedNumbersList = searchedNumber.split(",");
							let resultList: LimitationNumber[] = [];
							searchedNumbersList.forEach((number) => {
								let result: LimitationNumber = filteredLimitedNumbers.find((item) => item.limitedNumber === Number.parseInt(number));
								if (result !== undefined && resultList.find((item) => item.limitedNumber === result.limitedNumber) === undefined) {
									resultList.push(result);
								}
							});
							setDisplayedLimitationNumbers(resultList);
						} else if (searchedNumber !== 0) {
							filteredLimitedNumbers = filteredLimitedNumbers.filter((item) => item.limitedNumber === searchedNumber);
							setDisplayedLimitationNumbers(filteredLimitedNumbers);
						} else {
							setDisplayedLimitationNumbers(limitationNumbers);
						}
						break;

					case "2":
						//tens, hundreds, thousands
						let pattern: RegExp = /^[1-9]0+$|^[1-9][1-9]0+$|^[1-9][1-9][1-9]0+$/g;
						filteredLimitedNumbers = filteredLimitedNumbers.filter((item) => item.limitedNumber.toString().match(pattern));
						setDisplayedLimitationNumbers(filteredLimitedNumbers);
						break;

					case "3":
						//sequence
						let sequencePattern: RegExp = /^(12|23|34|45|56|67|78|89|123|234|345|456|567|678|789|1234|2345|3456|4567|5678)$/g;
						filteredLimitedNumbers = filteredLimitedNumbers.filter((item) => item.limitedNumber.toString().match(sequencePattern));
						setDisplayedLimitationNumbers(filteredLimitedNumbers);
						break;

					case "4":
						//all identical
						filteredLimitedNumbers = filteredLimitedNumbers.filter((item) => item.limitedNumber.toString().match(/^(\d)\1+$/g));
						setDisplayedLimitationNumbers(filteredLimitedNumbers);
						break;

					case "5":
						//include
						if (searchedNumber !== 0) {
							filteredLimitedNumbers = filteredLimitedNumbers.filter((item) => item.limitedNumber.toString().indexOf(searchedNumber.toString()) > -1);
							setDisplayedLimitationNumbers(filteredLimitedNumbers);
						} else {
							setDisplayedLimitationNumbers(limitationNumbers);
						}
						break;

					case "6":
						//exclude
						if (searchedNumber !== 0) {
							filteredLimitedNumbers = filteredLimitedNumbers.filter(
								(item) => item.limitedNumber.toString().indexOf(searchedNumber.toString()) === -1
							);
							setDisplayedLimitationNumbers(filteredLimitedNumbers);
						} else {
							setDisplayedLimitationNumbers(limitationNumbers);
						}
						break;

					case "7":
						//lowest available
						let lowest: LimitationNumber = filteredLimitedNumbers.find((item) => item.reservationState === State.FREE);
						let arrayLowest: LimitationNumber[] = [lowest];
						setDisplayedLimitationNumbers(arrayLowest);
						break;

					case "8":
						//highest available
						let highest: LimitationNumber = filteredLimitedNumbers.reverse().find((item) => item.reservationState === State.FREE);
						let arrayHighest: LimitationNumber[] = [highest];
						setDisplayedLimitationNumbers(arrayHighest);
						break;
					default:
						setDisplayedLimitationNumbers(limitationNumbers);
						break;
				}
			}
		},
		[searchOption, modelContextState.globalOrderType, limitationNumbers]
	);

	useEffect(() => {
		loadLimitationNumber(0);
	}, [searchOption, loadLimitationNumber]);

	/**
	 * Adds selected limitation number to list and saves it with the reservation state "SELECTED" or "FREE" in the database
	 * @param event
	 * @param reservation
	 */
	function saveLimitationNumberAsSelected(event, reservation: LimitationNumber) {

		if (event.currentTarget.checked) {
			if(assignedQuotes >= overAllQuote){
				showBanner("You reached your limit of available quotas.", "error");
				event.currentTarget.checked = false;
				return;
			}else {
				if (!startTimer) {
					setStartTimer(true);
					setTimer(EXPIRATION_TIME);
				}

				reservation.reservationState = State.SELECTED;
				if(isDealer){
					reservation.dealerNumber = partnerNumber;
				}else{
					reservation.importer = partnerNumber;
				}
				setAssignedQuotes(assignedQuotes + 1);
			}
		} else {
			reservation.reservationState = State.FREE;
			setAssignedQuotes(assignedQuotes-1);
		}

		let saveReservationRequest: SaveLimitationNumberRequest = {
			partnerNumber: isDealer? reservation.dealerNumber:reservation.importer,
			reservationState: reservation.reservationState,
			orderType: reservation.orderType,
			limitedNumber: reservation.limitedNumber
		};

		saveLimitationNumber(lenaAuthToken, saveReservationRequest)
			.then((response) => {
				if (reservation.reservationState === State.FREE) {
					let indexToRemove = selectedLimitationNumbers.indexOf(reservation);
					selectedLimitationNumbers.splice(indexToRemove, 1);
				} else {
					if (selectedLimitationNumbers.indexOf(reservation) === -1) {
						selectedLimitationNumbers.push(reservation);
					}
				}
			})
			.catch((error) => {
				if(event != null && event.currentTarget != null){
					event.currentTarget.checked = false;
				}
				showBanner(error.message, "error");
				loadLimitationNumbers();
				console.log(error);
			});
	}

	/**
	 * Saves all selected limitation numbers as "RESERVED" in the database
	 */
	function reserveLimitationNumbers() {
		setLoading(true);
		let reservationList = new Array<SaveLimitationNumberRequest>();
		selectedLimitationNumbers.forEach((res) => {
			let saveReservationRequest: SaveLimitationNumberRequest = {
				partnerNumber: isDealer? res.dealerNumber:res.importer,
				reservationState: State.RESERVED,
				orderType: res.orderType,
				limitedNumber: res.limitedNumber
			};
			reservationList.push(saveReservationRequest);
		});

		saveLimitationNumbers(lenaAuthToken, reservationList)
			.then((response) => {
				let messages : string = response[0].message;
				if(response.length > 1){
					for(let i=1; i<response.length; i++){
						messages += " " + response[i].message;
					}
				}
				showBanner(messages, "neutral");
			})
			.catch((error) => {
				showBanner(error.message, "error");
			}).finally(() => 	loadLimitationNumbers());
	}

	/**
	 * creates limitation number checkboxes in the UI
	 */
	function createLimitationNumberItems(): ReactElement {
		let checkboxes: any = [];

		// eslint-disable-next-line
		displayedLimitationNumbers.map((res: LimitationNumber, index) => {
					if(res.limitedNumber !== 0){
					let checked: boolean = res.reservationState !== State.FREE;
					let disabled: boolean =
						res.reservationState === State.RESERVED ||
						res.reservationState === State.BOOKED ||
						(!isDealer && res.reservationState === State.SELECTED && res.importer !== partnerNumber)
						|| (isDealer && res.reservationState === State.SELECTED && res.dealerNumber !== partnerNumber );

					if ( (!isDealer && res.reservationState === State.SELECTED && res.importer === partnerNumber) ||
						(isDealer && res.reservationState === State.SELECTED && res.dealerNumber === partnerNumber)) {
						if (selectedLimitationNumbers.indexOf(res) === -1) {
							selectedLimitationNumbers.push(res);
						}
					}
					let myReservations: boolean = (
						(res.reservationState === State.BOOKED || res.reservationState === State.RESERVED)
						&& ((!isDealer && res.importer === partnerNumber) || (isDealer && res.dealerNumber === partnerNumber)));

					let label  = myReservations ? res.limitedNumber + "*" : res.limitedNumber;
					let checkbox = (
						<div key={"res-" + index} className={"limitation-number"}>
							<input
								className={"limitation-number-checkbox"}
								id={"limitNr-" + res.limitedNumber}
								type="checkbox"
								name="limitNumber"
								checked={checked}
								disabled={disabled}
								onChange={(event) => saveLimitationNumberAsSelected(event, res)}
							/>
							<label className={"limitation-number-label"} aria-label={"limitNr-" + res.limitedNumber}>
								{label}
							</label>
						</div>
					);
						checkboxes.push(checkbox);
				}});

		return checkboxes;
	}

	return (
		<PContentWrapper width={"extended"}>
			{isBannerShow && (
				<PBanner width="basic" persistent={true} state={bannerInfo.state} className="create-model__banner_position">
					<span slot="title">{bannerInfo.title}</span>
					<span slot="description">{bannerInfo.description}</span>
				</PBanner>
			)}
			<div>
				<SearchFields
					setSearchedLimitedNumber={setSearchedLimitedNumber}
					searchedLimitedNumber={searchedLimitedNumber}
					loadLimitationNumber={loadLimitationNumber}
					searchOption={searchOption}
					setSearchOption={setSearchOption}
					excludedNumber = {excludedNumber}
					setExcludedNumber = {setExcludedNumber}
					includedNumber = {includedNumber}
					setIncludedNumber = {setIncludedNumber}
				/>
			</div>
			{loading && <PSpinner className={"spinner"} size={{ base: "small", l: "medium" }} />}
			{!loading && limitationNumbers.length > 0 && (
				<div>
					<PFlex direction={"row"} justifyContent={"space-between"}>
						<PFlex direction={"row"} justifyContent={"flex-start"}>
							{assignedQuotes !== null && overAllQuote !== null && <QuotaInformation assignedQuotes={assignedQuotes} overAllQuote={overAllQuote} />}
						</PFlex>
						<PFlex direction={"row"} justifyContent={"flex-end"}>
							{startTimer && <Timer time={timer} onTimeExpiration={loadLimitationNumbers} />}
							<PButton className={"refresh-btn"} variant={"tertiary"} hideLabel={true} icon={"refresh"} onClick={loadLimitationNumbers} />
						</PFlex>
					</PFlex>
					{assignedQuotes !== undefined && ordersByChanceCount !== undefined && (
						<PText weight={"bold"}>Orders by chance: {ordersByChanceCount}</PText>
					)}
					<PText className={"legend"}>"*" - My limitation numbers (reserved or booked)</PText>
					<PFlex className={"flex-limitation-numbers"} wrap="wrap" alignItems="flex-start">
						{createLimitationNumberItems()}
					</PFlex>
					<PFlex justifyContent={"flex-end"}>
						<PButton variant={"primary"} onClick={reserveLimitationNumbers} loading={loading} disabled={loading}>
							Reserve
						</PButton>
					</PFlex>
				</div>
			)}
		</PContentWrapper>
	);
}
