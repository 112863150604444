import React, {  useContext, useEffect, useState } from "react";
import {
	BannerState,
	PBanner,
	PButton,
	PContentWrapper,
	PGrid,
	PGridItem,
	PSpinner, PText
} from "@porsche-design-system/components-react";

import UserContext from "../../store/UserContext";
import { useModelState } from "../../store/ModelContext";
import Dropdown from "../../components/dropdown/dropdown";
import CustomLabel from "../../components/customLabel/customLabel";
import { GetLimitationNumberResponse, LimitationNumber, SaveLimitationNumberRequest, State } from "../../store/limitationNumber";
import { getLimitationNumbersByOrderTypeImporterAndState, transferLimitationNumber } from "../../api/LimitationNumberService";
import { getAllImporter } from "../../api/ModelImporterService";
import { Importer } from "../../store/importer";
import { ReservationResponse } from "../../store/response";

type Banner = {
	state: BannerState;
	title: string;
	description: string;
	duration: number;
};

export function TransferReservationTab(props) {

	const { partnerNumber, lenaAuthToken } = useContext(UserContext);
	const modelContextState = useModelState();
	const [showInfoBanner, setShowInfoBanner] = useState<boolean>(false);
	const [showTransferReservationBanner, setShowTransferReservation] = useState<boolean>(false);
	const [limitedEditionNrList, setLimitedEditionNrList] = useState<Array<number>>([]);
	const [selectedLimitedEditionNr, setSelectedLimitedEditionNr] = useState<string>("");
	const [importerList, setImporterList] = useState<Array<string>>([]);
	const [selectedImporterNr, setSelectedImporterNr] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [updateLists, setUpdateLists] = useState<boolean>(props.triggerUpdateLists);

	const initialBanner: Banner = {
		state: "neutral",
		title: "",
		description: "",
		duration: 6000
	};
	const [infoBanner, setInfoBanner] = useState(initialBanner);

	const showBanner = (message, resultState) => {
		let bannerInfo: Banner = {
			state: resultState,
			title: "LENA Notification",
			description: message,
			duration: 6000
		};
		setInfoBanner(bannerInfo);
		setShowInfoBanner(true);
		setTimeout(() => {
			setShowInfoBanner(false);
		}, bannerInfo.duration);
	};

	useEffect(() => {
		let reservationDataList: number[] = [];
		let importerDataList: string[] = [];
		resetFields();
		if (modelContextState.globalOrderType !== "" && ( updateLists || props.triggerUpdateLists)) {
			setLoading(true);
			getLimitationNumbersByOrderTypeImporterAndState(lenaAuthToken, modelContextState.globalOrderType, partnerNumber, State.RESERVED)
				.then((response: GetLimitationNumberResponse) => {
					response.reservationResponseList.forEach((reservation: LimitationNumber, index) => {
						reservationDataList[reservation.limitedNumber] = reservation.limitedNumber;
					});
					setLimitedEditionNrList(reservationDataList);
					setLoading(false)
				})
				.catch((error) => {
					console.log(error);
				});

			getAllImporter(lenaAuthToken)
				.then((response: Importer[]) => {
					response.forEach((importer) => {
						let description = importer.description === null ? "" : " - " + importer.description
						importerDataList[importer.importer] = importer.importer + description
					});
					setImporterList(importerDataList);
				})
				.catch((error) => {
					console.log(error);
				});
			setUpdateLists(false);
		}
	}, [lenaAuthToken, props.triggerUpdate, modelContextState.globalOrderType, partnerNumber, updateLists, props.triggerUpdateLists]);

	function handleTransferReservation() {
		if (modelContextState.globalOrderType === "") {
			showBanner("Please select a model.", "error");
		} else if (selectedLimitedEditionNr === "" || selectedImporterNr === "") {
			showBanner("Please select a limitation number and an importer.", "error");
		} else {
			setShowTransferReservation(false);
			setTimeout(() => {
				setShowTransferReservation(true);

			}, 100);
		}
	}

	function handleTransferReservationConfirm() {
		setShowTransferReservation(false);
		setLoading(true);

		let saveReservationRequest: SaveLimitationNumberRequest = {
			orderType: modelContextState.globalOrderType,
			limitedNumber: parseInt(selectedLimitedEditionNr),
			partnerNumber: selectedImporterNr,
			reservationState: State.RESERVED
		};
		transferLimitationNumber(lenaAuthToken, saveReservationRequest)
			.then((response : ReservationResponse) => {
				if(response.status === 200){
					showBanner("Limitation number " + selectedLimitedEditionNr + " transferred successfully to importer " + selectedImporterNr + ".", "neutral");
					resetFields();
					setUpdateLists(true);
				}else{
					showBanner(response.message, "error");
				}
				setLoading(false);
			})
			.catch((error) => {
				showBanner(error.message, "error");
				setLoading(false);
			});
	}

	function handleCancelTransferReservation() {
		setShowTransferReservation(false);
	}

	function resetFields () {
		let dropdownClearBtns : HTMLCollectionOf<Element> =  document.getElementsByClassName("clear_container__clear-button");
		for(let i=0; i<dropdownClearBtns.length; i++){
			let btn = dropdownClearBtns[i] as HTMLDivElement;
			btn.click();
		}
	}

	return (
		<div className="wrapper">
			{showInfoBanner && (
				<PBanner width="basic" state={infoBanner.state} persistent={true} className="create-model__banner_position">
					<span slot="title">{infoBanner.title}</span>
					<span slot="description">{infoBanner.description}</span>
				</PBanner>
			)}
			{showTransferReservationBanner && (
				<PBanner
					width="basic"
					persistent={false}
					onDismiss={() => handleCancelTransferReservation()}
					className="create-model__banner_position delete-confirm-banner">
					<span slot="title" className="delete-confirm-banner__title">
						{"Do you really want to transfer limitation number " + selectedLimitedEditionNr + " to importer " + selectedImporterNr + "?"}
					</span>
					<div slot="description" className="delete-confirm-banner__description">
						<PButton variant="primary" className="confirm-button" onClick={() => handleTransferReservationConfirm()}>
							Ok
						</PButton>
						<PButton className="confirm-button" onClick={() => handleCancelTransferReservation()}>
							Cancel
						</PButton>
					</div>
				</PBanner>
			)}
			<div>
				<PContentWrapper width={"extended"}>
					<PGrid>
						<PGridItem size={5}>
							<Dropdown
								className={"importer-transfer-input"}
								placeholder={"Limitation number to be transferred"}
								value={''}
								onChange={(value) => {
									if (value !== selectedLimitedEditionNr) {
										setSelectedLimitedEditionNr(value);
									}
								}}
								data={limitedEditionNrList}
								multipleSelections={false}
								clearable={true}
								searchable={true}
								showTooltip={false}
								labels={[<CustomLabel key="labe" position={"bottom-right"} text="Limited Edition Number" />]}
							/>
						</PGridItem>
						<PGridItem size={2} >
						<PText className={"importer-transfer-text"} >transfer to: </PText>
						</PGridItem>
						<PGridItem size={5}>
							<Dropdown
								className={"importer-transfer-input"}
								placeholder={"Importer"}
								value={""}
								onChange={(value) => {
									if (value !== selectedImporterNr) {
										setSelectedImporterNr(value);
									}
								}}
								data={importerList}
								multipleSelections={false}
								clearable={true}
								searchable={true}
								showTooltip={false}
								labels={[<CustomLabel key="labe" position={"bottom-right"} text="Importer" />]}
							/>
						</PGridItem>
						<PGridItem size={12}>
							{loading && <PSpinner className={"spinner"} size={{ base: "small", l: "medium" }} />}
						</PGridItem>
						<PGridItem size={12} className="submit-button">
							<PButton className="button cancel-button" onClick={() => resetFields()}>Cancel</PButton>
							<PButton className="button" variant={"primary"} onClick={() => handleTransferReservation()}>
								Transfer
							</PButton>
						</PGridItem>
					</PGrid>
				</PContentWrapper>
			</div>
		</div>
	);
}
