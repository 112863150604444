import React, { useContext, useEffect, useState } from "react";
import { ImporterQuote } from "../../store/types";
import { PButtonPure, PContentWrapper, PFlex, PGrid, PGridItem, PSpinner, PText } from "@porsche-design-system/components-react";
import { CustomTableHeader } from "../../components/customTable/customTableHeader";
import { CustomTable } from "../../components/customTable/customTable";
import TextField from "../../components/textField/textField";
import { RequestHeader } from "../../api/RequestHeader";
import UserContext from "../../store/UserContext";
import { useModelState } from "../../store/ModelContext";
import {
	getImportersQuoteByOrderTypeAndQuoteYear,
} from "../../api/ModelImporterQuoteService";

const ApiUrl = process.env.REACT_APP_BACKEND_URL;

function QuotaTab(props) {
	const { lenaAuthToken } = useContext(UserContext);
	const modelContextState = useModelState();
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [importerQuotes, setImporterQuotes] = useState<ImporterQuote[]>([]);
	const [loading, setLoading] = useState(false);
	const tableId = "quotaTable";

	useEffect(() => {
		if (modelContextState.globalOrderType === "") {
			setImporterQuotes([]);
		} else {
			setLoading(true);
			getImportersQuoteByOrderTypeAndQuoteYear(lenaAuthToken, modelContextState.globalOrderType, selectedYear)
				.then((response) => {
						response.forEach((iq) => {
							if (iq.quote === undefined) {
								iq.quote = null;
							}
						});
						setImporterQuotes(response);
						setLoading(false);
				})
				.catch((error) => console.log(error.message));
		}
	}, [modelContextState.globalOrderType, selectedYear, lenaAuthToken, props.triggerUpdate]);

	function saveModelWithImporterAndQuote(importerQuote: ImporterQuote){
		fetch(ApiUrl + "/saveModelWithImporterAndQuote", {
			method: "POST",
			headers: RequestHeader.createPutHeader(lenaAuthToken),
			body: JSON.stringify(importerQuote)
		}).then(async (response) => {
			if (!response.ok) {
				throw new Error(await response.text());
			}
		});
	}

	function handleImporterQuote(currentImporterNr: string, newQuote: string) {
		let newImporterQuotes = [...importerQuotes];
		let quoteField = document.getElementById("quote-for-" + currentImporterNr);

		const reg = new RegExp("^[0-9]+$");
		if (newQuote === "" || (reg.test(newQuote) && parseInt(newQuote) >= 0)) {
			let currentImporterQuote = newImporterQuotes.find((q) => q.importer === currentImporterNr);
			currentImporterQuote.quote = newQuote;
			currentImporterQuote.imported = false;
			setImporterQuotes(newImporterQuotes);
			quoteField.style.borderColor = "#c8cacb";
			saveModelWithImporterAndQuote(currentImporterQuote);
		} else {
			quoteField.style.borderColor = "red";
		}
	}

	// @ts-ignore
	// @ts-ignore
	return (
		<PContentWrapper width={"extended"}>
			<PContentWrapper width="extended" className={"model-select-wrapper"}>
				<PGrid>
					<PGridItem size={2}>
						<PText className={"quotatab-year-change"} size={"small"} > Fiscal year</PText>
					</PGridItem>
					<PGridItem size={10}>

						<PFlex direction={"row"} className={"quotatab-year-change"}>
							<PButtonPure
								hideLabel={true}
								icon={"arrow-head-left"}
								size={"small"}
								onClick={() => {
									setSelectedYear(selectedYear - 1);
								}}
							/>
							<PText className={"quotatab-year"} size={"small"}>
								{selectedYear}
							</PText>
							<PButtonPure
								hideLabel={true}
								icon={"arrow-head-right"}
								size={"small"}
								onClick={() => {
									setSelectedYear(selectedYear + 1);
								}}
							/>
						</PFlex>
					</PGridItem>
				</PGrid>
			</PContentWrapper>

			{loading && <PSpinner className={"spinner"} size={{ base: "small", l: "medium" }} /> }
			{!loading && Array.from(importerQuotes).length > 0 && (
				<CustomTable tableId={tableId} width={"800px"}>
					<thead>
						<tr>
							<CustomTableHeader text={"Partner No."} tableId={tableId} columnPos={0} sortable={true} />
							<CustomTableHeader text={"Importer"} tableId={tableId} columnPos={1} sortable={true} />
							<CustomTableHeader text={selectedYear} tableId={tableId} columnPos={2} sortable={true} width={"50px"} />
						</tr>
					</thead>
					<tbody>
						{Array.from(importerQuotes).map((importer) => {
							return (
								<tr key={importer.importer}>
									<td>{importer.importer}</td>
									<td>{importer.importerDescription}</td>
									<td style={{ width: "100px" }}>
										<TextField
											id={"quote-for-" + importer.importer}
											placeholder={""}
											onChange={(value) => handleImporterQuote(importer.importer, value)}
											isError={false}
											value={importer.quote === null ? "" : importer.quote}
											className={"quoteTab-quote"}
											disabled={importer.imported === true ? true : false}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</CustomTable>
			)}
		</PContentWrapper>
	);
}
export default QuotaTab;
