import React, { ReactElement, useState } from "react";
import { sortTableByColumn } from "./util";
import { PIcon } from "@porsche-design-system/components-react";

interface ICustomTableHeader {
	text: string | number;
	columnPos: number;
	tableId: string;
	sortable?: boolean;
	isDate?: boolean;
	width? : string;
}

export function CustomTableHeader(props: ICustomTableHeader): ReactElement {
	const [sortDirection, setSortDirection] = useState<string>("");

	function sortTable() {
		if(props.sortable) {
			let sortDirection = sortTableByColumn(props.tableId, props.columnPos, props.text.toString(), props.isDate);
			setSortDirection(sortDirection);
		}
	}

	return (
		<th id={props.text.toString()} onClick={(e) => sortTable()} style={{width: props.width}} >
			{props.text}
			{sortDirection === "desc" && <PIcon id={props.text.toString() + "_down"} name={"arrow-down"}></PIcon>}
			{sortDirection === "asc" && <PIcon id={props.text.toString() + "_up"} name={"arrow-up"}></PIcon>}
		</th>
	);
}
