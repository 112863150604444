export interface LimitationNumber {
	id: string;
	orderType: string;
	limitedNumber: number;
	importer: string;
	reservationState: string;
	currentDealerIndex: string;
	startReservedDateTime: string,
	endReservedDateTime?: string,
	dealerNumber?: string,
	commissionNumber?: string
	maxLimitedNumberWithTotalQuote?: string,
}

export interface GetLimitationNumberResponse {
	totalQuote: number,
	reservationResponseList : Array<LimitationNumber>
}

export interface ReservationRequest {
	orderType: string;
	state?: State;
}

export interface SaveLimitationNumberRequest {
	orderType: string;
	limitedNumber: number;
	partnerNumber: string;
	reservationState: string;
	dealerNumber?: string;
}

export interface SaveDealerReservationRequest {
	orderType: string;
	importer: string;
	dealer: string;
	reservationState: string;
	limitedNumber: number;
}
export enum State {
	FREE = "FREE",
	SELECTED = "SELECTED",
	RESERVED = "RESERVED",
	BOOKED = "BOOKED"
}
