import { RouteProps } from "react-router-dom";
import ModelMaintain from "./pages/maintainmodel";
import SearchModel from "./pages/searchmodel";
import { USER_ROLE } from "./store/user";
import Reports from "./pages/report";
import Administration from "./pages/administration";

export type RouteType = RouteProps & {
	name: string;
	path: string; // override string | string[]
};

export function getRoutes(roles): RouteType[] {

	let routesMap = new Map();

	let routes = [];

	const maintainModel = {
		order : 1,
		name: "Maintain Model",
		path: "/maintainmodel",
		component: ModelMaintain
	};

	const searchNumbers = {
		order : 2,
		name: "Search Numbers",
		path: "/search",
		component: SearchModel
	};

	const reports = {
		order : 3,
		name: "Reports",
		path: "/reports",
		component: Reports
	};

	const administration = {
		order : 4,
		name: "Administration",
		path: "/administration",
		component: Administration

	};

	roles.forEach( role => {
		switch (role) {
			case USER_ROLE.MASTER_DATA_MAINTENANCE:
				routesMap.set(1,maintainModel);
				break;

			case USER_ROLE.ORDER_MANAGEMENT:
				routesMap.set(2,searchNumbers);
				//routesMap.set(3,reports);
				break;

			case USER_ROLE.REPORTING:
				routesMap.set(3,reports);
				break;

			case USER_ROLE.VIP_ORDER_MANAGEMENT:
				routesMap.set(2,searchNumbers);
				routesMap.set(4,administration);
				//routesMap.set(3,reports);
				break;

			case USER_ROLE.IMPORTER:
			case USER_ROLE.DEALER:
				routesMap.set(2,searchNumbers);
				break;
		}
	});
	routesMap.forEach(route => {
		routes.push(route);
	});
	routes.sort(compare)
	return routes;
}

function compare( a, b ) {
	if ( a.order < b.order ){
		return -1;
	}
	if ( a.order > b.order ){
		return 1;
	}
	return 0;
}
