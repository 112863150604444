import { RequestHeader } from "./RequestHeader";

const ApiUrl = process.env.REACT_APP_BACKEND_URL;

export function getModels(token: string) {
	return fetch(ApiUrl + "/getModels", {
		method: "GET",
		headers: RequestHeader.createGetHeader(token)
	})
		.catch((err) => {
			throw new Error(err);
		})
		.then(async (response) => {
			if (!response.ok) {
				throw new Error(await response.text());
			}
			return response.json();
		});
}

export function updateModel(token: string, request): Promise<string> {
	return fetch(ApiUrl + "/updateModel", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(request)
	})
		.catch((err) => {
			throw new Error(err);
		})
		.then(async (response) => {
			if (!response.ok) {
				throw new Error(await response.text());
			}
			return response.text();
		});
}

export function saveModel(token: string, request): Promise<string> {
	return fetch(ApiUrl + "/saveModel", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(request)
	})
		.catch((err) => {
			throw new Error(err);
		})
		.then(async (response) => {
			if (!response.ok) {
				throw new Error(await response.text());
			}
			return response.text();
		});
}

export function deleteModel(token: string, orderType: string): Promise<string> {
	return fetch(ApiUrl + "/deleteModel", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify({
			orderType: orderType
		})
	})
		.catch((err) => {
			throw new Error(err);
		})
		.then(async (response) => {
			if (!response.ok) {
				throw new Error(await response.text());
			}
			return response.text();
		});
}
