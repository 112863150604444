import './customFieldWrapper.scss';

import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';

import ResetButton from '../resetButton/resetButton';
import CustomTooltip from '../customTooltip/customTooltip';
import TextField from '../textField/textField';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';

export interface ICustomFieldWrapperProps {
  className?: string,
  clearable?: boolean;
  clearAction?: () => void;
  showClear?: boolean
  children: React.ReactNode;
  placeholder?: string;
  hasValue?: boolean;
  disabled?: boolean;
  labels?: [React.ReactNode];
  showTooltip?: boolean;
  tooltip?: string;
  maskBeforeEdit?: boolean;
  fieldValue?: string;
  onClickOutside?: Function;
  multiline?: boolean;
}

const CustomFieldWrapper = (props: ICustomFieldWrapperProps) => {
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsBeingEdited(false);
      props.onClickOutside && props.onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  return (
    <div ref={ref} className={classnames('custom-field', props.className, `${props.disabled ? 'disabled' : ''}`)}>
      <div className={'custom-field__wrapper'}>
        {
          (!props.maskBeforeEdit || isBeingEdited) ?
            (
              <>
                {props.children}
                {
                  props.placeholder && (
                    <label className={`${props.multiline ? 'custom-field__custom-label--multiline' : 'custom-field__custom-label'} ${props.hasValue ? '' : 'custom-field__custom-label--empty'}`}>
                      {props.placeholder}
                    </label>
                  )
                }

              </>
            ) :
            <TextField placeholder={props.placeholder} value={props.fieldValue} disabled />
        }

        {props.labels}
        {
          (props.maskBeforeEdit && !isBeingEdited) && <EditIcon className={'custom_field__edit_icon'} onClick={() => { setIsBeingEdited(true) }} />
        }
      </div>
      {
        props.showTooltip && (
          <CustomTooltip
            tooltip={props.tooltip}
          />
        )
      }
      {
        props.clearable && (
          <ResetButton
            show={props.showClear}
            action={() => { props.clearAction && props.clearAction(); }}
          />
        )
      }
    </div>
  )
};

CustomFieldWrapper.defaultProps = {
  disabled: false,
  showClear: false,
  showTooltip: false,
};

export default CustomFieldWrapper;
