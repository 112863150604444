import React, { ReactElement, useContext, useState, useEffect, useCallback  } from "react";
import { BannerState, PBanner, PContentWrapper, PSpinner } from "@porsche-design-system/components-react";
import TextField from "../../components/textField/textField";
import { getLimitationNumberHistory } from "../../api/LimitationNumberService";
import UserContext from "../../store/UserContext";
import { useModelState } from "../../store/ModelContext";
//import { USER_ROLE } from "../../store/user";
import CustomLabel from "../../components/customLabel/customLabel";
import { CustomTable } from "../../components/customTable/customTable";
import { CustomTableHeader } from "../../components/customTable/customTableHeader";
import { LimitedNumberHistory } from "../../store/types";
import {
	showDateTimeAsStringWithMilliseconds, showDateTimeAsStringWithSeconds
} from "../../utils";
import {State} from "../../store/limitationNumber";

interface Banner {
	state: BannerState;
	title: string;
	description: string;
	duration?: number;
}

export function LimitationNumberHistory(props): ReactElement {
	const { lenaAuthToken } = useContext(UserContext);
	const modelContextState = useModelState();
	const [loading, setLoading] = useState<boolean>(false);
	const tableId = "historyTable";
	const [history, setHistory] = useState<LimitedNumberHistory[]>([]);
	const [isBannerShow, setBannerShow] = useState(false);
	const [limitedNumber, setLimitedNumber] = useState("");
	const [bannerInfo, setBannerInfo] = useState<Banner>({
		state: "neutral",
		title: "LENA Notification",
		description: "",
		duration: 6000
	});

	const handleLimitedNumber = useCallback(() => {
		
		if(limitedNumber !== "" && !isNaN(Number(limitedNumber))){
			if (modelContextState.globalOrderType === "") {
				showBanner("Please select a model.", "error");
			} else{
		setLoading(true);
		getLimitationNumberHistory(lenaAuthToken, modelContextState.globalOrderType, limitedNumber)
		.then((response) => {
			response.forEach((iq) => {
				if (iq.quote === undefined) {
					iq.quote = null;
				}
			});
			setHistory(response);
			setLoading(false);
	})
	.catch((error) => console.log(error.message));
	}}
	else{
		setLoading(false);
		setHistory([]);
	}
	}, [limitedNumber, lenaAuthToken, modelContextState.globalOrderType]);

	useEffect(() => {
		const timeOutId = setTimeout(() => handleLimitedNumber(), 500);
		return () => clearTimeout(timeOutId);
	  }, [handleLimitedNumber]);
	
	function showBanner(message, resultState) {
		let bannerInfo: Banner = {
			state: resultState,
			title: "LENA Notification",
			description: message,
			duration: 6000
		};
		setBannerInfo(bannerInfo);
		setBannerShow(true);

		const timer = setTimeout(() => {
			setBannerShow(false);
		}, bannerInfo.duration);
		return () => clearTimeout(timer);
	}

	return (
		<PContentWrapper width={"extended"}>
			{isBannerShow && (
				<PBanner width="basic" persistent={true} state={bannerInfo.state} className="info-banner">
					<span slot="title">{bannerInfo.title}</span>
					<span slot="description">{bannerInfo.description}</span>
				</PBanner>
			)}
			<div className={"content-div"}>
			<TextField
									className={"create-model-details__input_section__field"}
									placeholder={"Please type in limited number*"}
									labels={[<CustomLabel key="labe" position={"bottom-right"} text="Limitation" />]}
									onChange={(value) => setLimitedNumber(value)}
									isError={isNaN(Number(limitedNumber))}
									errorMsg="Limitation has to be a positive natural number."
								/>
			</div>

			<div>
			{loading && <PSpinner className={"spinner"} size={{ base: "small", l: "medium" }} />}
			{!loading && (
				<CustomTable tableId={tableId}>
					<thead>
						<tr>
							<CustomTableHeader text={"Limited Number"} tableId={tableId} columnPos={0} sortable={true} />
							<CustomTableHeader text={"Status"} tableId={tableId} columnPos={1} sortable={true} />
							<CustomTableHeader text={"Create Date"} tableId={tableId} columnPos={2} sortable={true}  isDate={true} />
							<CustomTableHeader text={"Reservation End"} tableId={tableId} columnPos={3} sortable={true}  isDate={true} />
							<CustomTableHeader text={"Action"} tableId={tableId} columnPos={4} sortable={true} />
							<CustomTableHeader text={"Commission Number"} tableId={tableId} columnPos={5} sortable={true} />
							<CustomTableHeader text={"Importer"} tableId={tableId} columnPos={6} sortable={true} />
							<CustomTableHeader text={"Dealer"} tableId={tableId} columnPos={7} sortable={true} />
							<CustomTableHeader text={"Triggered by"} tableId={tableId} columnPos={8} sortable={true} />
							<CustomTableHeader text={"VGUID"} tableId={tableId} columnPos={9} sortable={true} />
						</tr>
					</thead>
					<tbody>
						{Array.from(history).map((elem: LimitedNumberHistory) => (
							<tr key={elem.limitedNumber}>
								<td>{elem.limitedNumber}</td>
								<td>{elem.reservationState}</td>
								<td title={showDateTimeAsStringWithMilliseconds(elem.date)}>{showDateTimeAsStringWithSeconds(elem.date)}</td>
								<td title={showDateTimeAsStringWithMilliseconds(elem.endReservedDateTime)}>
									{
										(elem.reservationState === State.RESERVED || elem.reservationState === State.SELECTED) &&
											showDateTimeAsStringWithSeconds(elem.endReservedDateTime)
									}
								</td>
								<td>{elem.action}</td>								
								<td>{elem.commissionNumber}</td>								
								<td>{elem.importer}</td>								
								<td>{elem.dealer}</td>								
								<td>{elem.user}</td>
								<td>{elem.vguid}</td>
							</tr>
						))}
					</tbody>
				</CustomTable>
			)}
			</div>
		</PContentWrapper>
	);
}
