
export class RequestHeader {
	static createGetHeader(token: string): Record<string, string> {
		return {
			Accept: "application/json",
			Authorization: token
		};
	}

	static createPutHeader(token: string): Record<string, string> {
		return {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: token
		};
	}

	static createDeleteHeader(token: string): Record<string, string> {
		return {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: token
		};
	}
}
