import React, { useState } from "react";
import { PTabsBar } from "@porsche-design-system/components-react";
import "./index.scss";
/*import UserContext from "../../store/UserContext";*/
import { AllLimitationNumbers } from "./AllLimitationNumbers";
import { LimitationNumberHistory } from "./LimitationNumberHistory";

export default function Reports() {
	const [triggerUpdate, handleTriggerUpdate] = useState<boolean>(false);
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
	/*
const { roles } = useContext(UserContext);

const [showTabs] = useState<boolean>(
	roles.includes(USER_ROLE.ORDER_MANAGEMENT) || roles.includes(USER_ROLE.REPORTING) || roles.includes(USER_ROLE.VIP_ORDER_MANAGEMENT)
);
 */

	const handleTabChange = (e) => {
		handleTriggerUpdate(!triggerUpdate);
		let tabIndex = e.detail.activeTabIndex;
		setActiveTabIndex(tabIndex);

		switch (tabIndex) {
			case 0:
			document.getElementById("tab-panel-0").hidden = false;
			document.getElementById("tab-panel-1").hidden = true;
			handleTriggerUpdate(true);
			break;
			case 1:
			document.getElementById("tab-panel-0").hidden = true;
			document.getElementById("tab-panel-1").hidden = false;						
			handleTriggerUpdate(false);
			break;
			default:
			document.getElementById("tab-panel-0").hidden = true;
			document.getElementById("tab-panel-1").hidden = true;
			handleTriggerUpdate(false);
		}
	};

	return (
		<div>
			<PTabsBar activeTabIndex={activeTabIndex} onTabChange={handleTabChange} className="tab-bar">
				<button type={"button"} id={"tab-item-0"} aria-controls={"tab-panel-0"}>
					All Limitation Numbers
				</button>
				<button type={"button"} id={"tab-item-1"} aria-controls={"tab-panel-1"}>
					Number History
				</button>
			</PTabsBar>

			<div className={"tab-panel"}>
				<div id="tab-panel-0" role="tabpanel" aria-labelledby="tab-item-0">
					<AllLimitationNumbers />
				</div>
				<div id="tab-panel-1" hidden role="tabpanel" aria-labelledby="tab-item-1">
					<LimitationNumberHistory triggerUpdate={handleTriggerUpdate} />
				</div>
			</div>
		</div>
	);
}
