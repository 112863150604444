import './dropdown.scss';

import React, {
  ReactElement,
  useEffect,
  useRef,
  useState
} from "react";
import classnames from 'classnames';

import CustomFieldWrapper from '../customFieldWrapper/customFieldWrapper';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-head-down.svg';

import { PCheckboxWrapper } from '@porsche-design-system/components-react';

export interface IDropdownProps {
  placeholder?: string;
  value?: string;
  className?: string;
  onChange?: (value: string) => void;
  clearable?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  labels?: [React.ReactNode];
  showTooltip?: boolean;
  tooltip?: string;
  maskBeforeEdit?: boolean;
  data: object;
  multipleSelections?: boolean;
  maxSelections?: number;
  multipleSelectionsCustomMessage?: string;
  searchable?: boolean;
  onDropdownOpen?: () => string;
  darkTheme?: boolean;
  selectAllKey?: string;
  clearSelection?: boolean;
}

const Dropdown = (props: IDropdownProps) : ReactElement => {
  const { data, selectAllKey, multipleSelectionsCustomMessage, maxSelections } = props;
  const [keys, setKeys] = useState([]);
  const [preventOnChangeCall, setPreventOnChangeCall] = useState(true);
  const [dropdownVisible, setDropdownVisibility] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [dropdownWidth, setDropdownWidth] = useState(0);
  const fieldValue = keys;

  const selectedNumberOfValues = allSelected ? keys.length - 1 : keys.length;
  const displayValue =
    multipleSelectionsCustomMessage && keys.length > 1
      ? selectedNumberOfValues + multipleSelectionsCustomMessage
      : keys.map(key => data[key]).toString();

  const ref = useRef(null);
  const inputRef = useRef(null);

  const handleSelect = key => {
    setPreventOnChangeCall(false);
    if (!props.multipleSelections) {
      setKeys([key]);
      setDropdownVisibility(false);
    } else {
      if (key === selectAllKey && !keys.includes(key)) {
        setKeys(Object.keys(data));
        setAllSelected(true);
      } else if (key === selectAllKey && keys.includes(key)) {
        setKeys([]);
        setAllSelected(false);
      } else if (!keys.includes(key) && keys.length !== maxSelections) {
        setKeys([...keys, key]);
      } else {
        setKeys(keys.filter(item => item !== key));
      }
    }
    setSearchTerm(null);
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdownVisibility(false);
      setSearchTerm(null);
    }
  };

  const setDropdownSizes = () => {
    const bounds = inputRef && inputRef.current.getBoundingClientRect();
    if (bounds) {
      setDropdownWidth(bounds.width - 1);
    }
  };

  const { onChange, clearSelection } = props

  useEffect(() => {
    if (!preventOnChangeCall) onChange(keys.toString());
    if (clearSelection && keys.length > 0) {
      setKeys([]);
    }
  }, [keys, preventOnChangeCall, clearSelection, onChange]);

  useEffect(() => {
    props.value && props.value !== '' ? setKeys(props.value.split(',')) : setKeys([]);
  }, [props.value]);

  useEffect(() => {
    if (dropdownVisible) {
      window.addEventListener('resize', setDropdownSizes);

      setDropdownSizes();
    } else {
      window.removeEventListener('resize', setDropdownSizes);
    }
    return () => {
      window.removeEventListener('resize', setDropdownSizes);
    };
  }, [dropdownVisible]);

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('mousedown', handleClickOutside, true);
    } else {
      document.removeEventListener('mousedown', handleClickOutside, true);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [dropdownVisible]);

  const { onDropdownOpen } = props

  useEffect(() => {
    onDropdownOpen && dropdownVisible && onDropdownOpen();
  }, [dropdownVisible, onDropdownOpen]);

  const darkTheme = props.darkTheme && 'dark';

  return (
    <div ref={ref} className={classnames(props.className, 'custom-dropdown-wrapper')}>
      <CustomFieldWrapper
        className={'field-overflow'}
        clearable={props.clearable}
        clearAction={() => {
          setKeys([]);
          setSearchTerm(null);
          props.onChange && props.onChange('');
          setDropdownVisibility(false);
        }}
        showClear={fieldValue.length !== 0 || !!searchTerm}
        placeholder={props.placeholder}
        hasValue={fieldValue.length !== 0 || !!searchTerm}
        disabled={props.disabled}
        labels={props.labels}
        showTooltip={props.showTooltip}
        tooltip={props.tooltip}
        maskBeforeEdit={props.maskBeforeEdit}
        fieldValue={displayValue}
      >
        {
          <>
            <div
              ref={inputRef}
              className={classnames('custom-dropdown-input', `${darkTheme}`, `${props.disabled && 'disabled'}`)}
              onClick={() => {
                !props.disabled && setDropdownVisibility(!dropdownVisible);
              }}
            >
              {props.searchable ? (
                <input
                  value={searchTerm !== null ? searchTerm : displayValue}
                  className={classnames('custom-dropdown__search-input', {
                    'custom-dropdown__search-input--hasPlaceholder': !!props.placeholder
                  })}
                  onChange={e => {
                    setSearchTerm(e.target.value);
                    if (!dropdownVisible) {
                      setDropdownVisibility(true);
                    }
                  }}
                />
              ) : (
                <>{displayValue}</>
              )}
              <ArrowDown className={classnames('custom_dropdown__icon', `${darkTheme}`)} />
            </div>
          </>
        }
      </CustomFieldWrapper>
      {dropdownVisible && (
        <ul className={'custom-dropdown-option-container'} style={{ width: dropdownWidth - 1 }}>
          {Object.keys(data)
          .sort()
          .filter(key => data[key].toString().toLowerCase().includes(searchTerm ? searchTerm.toLowerCase() : ''))
          .map(key => (
            <li
              className={classnames(
                'custom-dropdown-option-container__option',
                fieldValue.includes(key) ? 'custom-dropdown-option-container__option--selected' : ''
              )}
              key={key}
              onClick={() => handleSelect(key)}
            >
              {props.multipleSelections && (
                <PCheckboxWrapper hideLabel={false} label={data[key].toString()} style={{ marginRight: '0.5rem' }}>
                  <input type={'checkbox'} checked={keys.includes(key)} onChange={() => handleSelect(key)} />
                </PCheckboxWrapper>
              )}
              {!props.multipleSelections && data[key].toString()}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

Dropdown.defaultProps = {
  disabled: false,
  clearable: false,
  showTooltip: false,
  maskBeforeEdit: false,
  searchable: true
};

export default Dropdown;
