import { RequestHeader } from "./RequestHeader";
import {
	GetLimitationNumberResponse,
	LimitationNumber,
	ReservationRequest,
	SaveDealerReservationRequest,
	SaveLimitationNumberRequest,
	State
} from "../store/limitationNumber";
import { SaveReservationResponseItem } from "../store/types";
import { UploadResponse } from "../store/response";

const ApiUrl = process.env.REACT_APP_BACKEND_URL;

/**
 * Returns a list of all reservation objects by order type
 * @param orderType
 */
export function getLimitationNumbersByOrderType(token: string, orderType: string): Promise<Array<LimitationNumber>> {
	let request: ReservationRequest = {
		orderType: orderType
	};
	return fetch(ApiUrl + "/getLimitationNumbersByOrderType", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(request)
	})
		.catch((err) => {
			throw new Error(err);
		})
	.then(async (response) => {
		if (!response.ok) {
			throw new Error(await response.text());
		}
		return await response.json();
	});
}

/**
 * Returns a list of all reservation objects by order type, importer and state
 * @param orderType
 * @param importer partner number of the user
 * @param state reservation state
 */
export function getLimitationNumbersByOrderTypeImporterAndState(token: string, orderType: string, importer: string, state: State): Promise<GetLimitationNumberResponse> {
	let request = {
		orderType: orderType,
		importer: importer,
		reservationState: state
	};
	return fetch(ApiUrl + "/getLimitationNumbersByOrderTypeImporterAndState", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(request)
	})
	.catch((err) => {
		throw new Error(err);
	})
	.then(async (response) => {
		if (!response.ok) {
			throw new Error(await response.text());
		}
		return await response.json();
	});
}

/**
 * Returns a list of all reservation objects by order type and importer
 * @param orderType
 * @param partnerNumber partner number of the user
 */
export function getLimitationNumbersByOrderTypeImporter(token: string, orderType: string, partnerNumber: string): Promise<Array<LimitationNumber>> {
	let request = {
		orderType: orderType,
		importer: partnerNumber,
		includeOrdersByChance: true
	};
	return fetch(ApiUrl + "/getLimitationNumbersByOrderTypeAndImporter", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(request)
	})
	.catch((err) => {
		throw new Error(err);
	})
	.then(async (response) => {
		if (!response.ok) {
			throw new Error(await response.text());
		}
		return await response.json();
	});
}

/**
 * Returns a list of all reservation objects by order type and dealer
 * @param orderType
 * @param partnerNumber partner number of the user
 */
export function getLimitationNumbersByOrderTypeDealer(token: string, orderType: string, partnerNumber: string): Promise<Array<LimitationNumber>> {
	let request = {
		orderType: orderType,
		dealerNumber: partnerNumber,
		includeOrdersByChance: true
	};
	return fetch(ApiUrl + "/getLimitationNumbersByOrderTypeAndDealer", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(request)
	})
	.catch((err) => {
		throw new Error(err);
	})
	.then(async (response) => {
		if (!response.ok) {
			throw new Error(await response.text());
		}
		return await response.json();
	});
}

/**
 * Saves a reservation
 * @param limitationNumber
 */
export async function saveLimitationNumber(token: string, limitationNumber: SaveLimitationNumberRequest) : Promise<SaveReservationResponseItem> {
	return fetch(ApiUrl + "/saveLimitationNumber", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(limitationNumber)
	})
	.catch((err) => {
		throw new Error(err);
	})
	.then(async (response) => {
		if (!response.ok) {
			throw new Error(await response.text());
		}
		return await response.json();
	});
}

export async function transferLimitationNumber(token: string, limitationNumber: SaveLimitationNumberRequest) {
	return fetch(ApiUrl + "/transferLimitationNumber", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(limitationNumber)
	})
	.catch((err) => {
		throw new Error(err);
	})
	.then(async (response) => {
		if (!response.ok) {
			throw new Error(await response.text());
		}
		return await response.json();
	});
}

/**
 * Saves multiple reservations
 * @param List of reservations
 */
export async function saveLimitationNumbers(token: string, reservations: Array<SaveLimitationNumberRequest>): Promise<Array<SaveReservationResponseItem>> {
	return fetch(ApiUrl + "/saveLimitationNumbers", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(reservations)
	})
	.catch((err) => {
		throw new Error(err);
	})
	.then(async (response) => {
		let data = await response.json();
		if (!response.ok) {
			let messages : string = data[0].message;
			if(data.length > 1) {
				for (let i = 1; i < data.length; i++) {
					messages += " " + data[i].message;
				}
			}
			throw new Error(messages);
		}
		return data;
	});
}

/**
 * Saves multiple reservations for Dealer
 * @param List of reservations
 */
export async function saveDealerLimitationNumber(token: string, reservation:SaveDealerReservationRequest) {
	return fetch(ApiUrl + "/saveDealerLimitationNumber", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(reservation)
	})
	.catch((err) => {
		throw new Error(err);
	})
	.then(async (response) => {
		if (!response.ok) {
			throw new Error(await response.text());
		}
		return await response.text();
	});
}


/**
 * Deletes a reservation
 * @param List of reservations
 */
export function cancelReservation(token: string, reservationId: string) {
	let request = {
		id: reservationId
	}
	return fetch(ApiUrl + "/cancelReservation", {
		method: "POST",
		headers: RequestHeader.createDeleteHeader(token),
		body: JSON.stringify(request)
	})
	.catch((err) => {
		throw new Error(err);
	})
	.then(async (response) => {
		if (!response.ok) {
			throw new Error(await response.text());
		}
		return await response.text();
	});
}


/**
 * Upload of VIP reservations
 */
export function uploadVipReservations(token: string, base64EncodedString: string | ArrayBuffer, fileName: string, orderType: string): Promise<Array<UploadResponse>>  {
	let request = {
		vipReservations : base64EncodedString,
		fileName: fileName,
		orderType: orderType
	}
	return fetch(ApiUrl + "/uploadVipReservations", {
		method: "POST",
		headers:RequestHeader.createPutHeader(token),
		body: JSON.stringify(request)
	})
	.catch((err) => {
		throw new Error(err);
	})
	.then(async (response) => {
		if (!response.ok) {
			throw new Error(await response.text());
		}
		return await response.json();
	});
}

/**
 * Returns a csv - file with the limitation numbers of the current user
 * @param token
 * @param orderType
 * @param partnerNumber
 */
export function exportMyNumbers(token: string, orderType: string, partnerNumber: string): Promise<any> {
	let request = {
		orderType: orderType,
		partnerNumber: partnerNumber
	};

	return fetch(ApiUrl + "/exportMyLimitationNumbers", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(request)
	})
	.catch((err) => {
		throw new Error(err);
	})
	.then(async (response) => {
		return response;
	});
}

/**
 *
 * @param token
 * @param orderType
 */
export function exportAllLimitationNumbers(token: string, orderType: string): Promise<Response> {
	let request = {
		orderType: orderType
	};
	return fetch(ApiUrl + "/exportLimitationNumbers", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(request)
	})
	.catch((err) => {
		throw new Error(err);
	})
	.then(async (response) => {
		return response;
	});
}

export function getLimitationNumberHistory(token: string, orderType: string, limitedNumber: string) {
	let request = {
		orderType: orderType,
		limitedNumber: limitedNumber
	};
	return fetch(ApiUrl + "/getLimitationNumberHistory", {
		method: "POST",
		headers: RequestHeader.createGetHeader(token),
		body: JSON.stringify(request)
	})
		.catch((err) => {
			throw new Error(err);
		})
		.then(async (response) => {
			if (!response.ok) {
				throw new Error(await response.text());
			}
			return response.json();
		});
}
