import React, { createContext, ReactElement, useReducer } from "react";

type ControlProps = {
	children: React.ReactNode;
}
type Action =
					|	{ type: "UPDATE_MODEL_LIST"; doUpdate: boolean }
					| {type: "SELECT_ORDER_TYPE", orderType: string};
type Dispatch = (action: Action) => void;


export interface modelContextState {
	globalOrderType: string;
	doUpdate: boolean;
}

const emptyInitialContextValue: modelContextState = {
	globalOrderType: "",
	doUpdate : true
};

const ModelStateContext = createContext(emptyInitialContextValue);
const ModelDispatchContext = createContext<Dispatch | undefined>(undefined);

function modelReducer(state: modelContextState, action: Action) {
	const newState = { ...state };
	switch (action.type) {
		case "UPDATE_MODEL_LIST":
			newState.doUpdate = action.doUpdate
			break;
		case "SELECT_ORDER_TYPE" :
			newState.globalOrderType = action.orderType
			break;
		default:
			break;
	}
	return newState;
}

function ModelStateProvider({children}: ControlProps): ReactElement {
	const [state, dispatch] = useReducer(modelReducer, emptyInitialContextValue);

	return (
		<ModelStateContext.Provider value={state}>
			<ModelDispatchContext.Provider value={dispatch}>{children}</ModelDispatchContext.Provider>
		</ModelStateContext.Provider>
	);
}

function useModelState(): modelContextState {
	const context = React.useContext(ModelStateContext);
	if (context === undefined) {
		throw new Error("something went wrong");
	}
	return context;
}

function useModelDispatch(): (action: Action) => void  {
	const context = React.useContext(ModelDispatchContext);
	if (context === undefined) {
		throw new Error("something went wrong");
	}
	return context;
}
export { ModelStateProvider, useModelState, useModelDispatch };
