export function sortTableByColumn(tableId: string, columnNr: number, columnId: string, isDate: boolean): string {
	let table,
		rows,
		switching,
		i,
		x,
		y,
		shouldSwitch,
		dir,
		switchcount = 0;

	table = document.getElementById(tableId);

	//sorting
	switching = true;
	// Set the sorting direction to ascending:
	dir = "asc";
	/* Make a loop that will continue until
	no switching has been done: */
	while (switching) {
		// Start by saying: no switching is done:
		switching = false;
		rows = table.rows;
		/* Loop through all table rows (except the
		first, which contains table headers): */
		for (i = 1; i < rows.length - 1; i++) {
			// Start by saying there should be no switching:
			shouldSwitch = false;
			/* Get the two elements you want to compare,
			one from current row and one from the next: */
			x = rows[i].getElementsByTagName("TD")[columnNr];
			y = rows[i + 1].getElementsByTagName("TD")[columnNr];
			/* Check if the two rows should switch place,
			based on the direction, asc or desc: */
			let cmpX;
			let cmpY;

			//check if table has some innerElement like input-field
			if (x.children.length > 0) {
				x = x.children[0].getElementsByTagName("input")[0];
				cmpX = isNaN(parseInt(x.value)) ? x.value.toLowerCase() : parseInt(x.value);
			} else {
				if (isDate) {
					if (x.title.length > 0)
					{
						cmpX = x.title
					}
					else
					{
						cmpX = x.innerHTML;
					}
				} else {
					cmpX = isNaN(parseInt(x.innerHTML)) ? x.innerHTML.toLowerCase() : parseInt(x.innerHTML);
				}
			}

			if (y.children.length > 0) {
				y = y.children[0].getElementsByTagName("input")[0];
				cmpY = isNaN(parseInt(y.value)) ? y.value.toLowerCase() : parseInt(y.value);
			} else {
				if (isDate) {
					if (y.title.length > 0)
					{
						cmpY = y.title
					}
					else
					{
						cmpY = y.innerHTML;
					}
				} else {
					cmpY = isNaN(parseInt(y.innerHTML)) ? y.innerHTML.toLowerCase() : parseInt(y.innerHTML);
				}
			}

			if (dir === "asc") {
				if (cmpX > cmpY) {
					shouldSwitch = true;
					break;
				}

				if (isNaN(parseInt((cmpY))) && !isNaN(parseInt(cmpX))) {
					shouldSwitch = true;
					break;
				}

				if(cmpY instanceof Date && cmpX instanceof Date){
					// @ts-ignore
					if((cmpY - cmpX) > 0){
						shouldSwitch = true;
						break;
					}
				}
			} else if (dir === "desc") {
				if (cmpX < cmpY) {
					shouldSwitch = true;
					break;
				}

				if (isNaN(parseInt((cmpX))) && !isNaN(parseInt(cmpY))) {
					shouldSwitch = true;
					break;
				}

				if(cmpY instanceof Date && cmpX instanceof Date) {
					// @ts-ignore
					if ((cmpX - cmpY) > 0) {
						shouldSwitch = true;
						break;
					}
				}
			}
		}
		if (shouldSwitch) {
			/* If a switch has been marked, make the switch
			and mark that a switch has been done: */
			rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
			switching = true;
			// Each time a switch is done, increase this count by 1:
			switchcount++;
		} else {
			/* If no switching has been done AND the direction is "asc",
			set the direction to "desc" and run the while loop again. */
			if (switchcount === 0 && dir === "asc") {
				dir = "desc";
				switching = true;
			}
		}
	}

	//hide and show right sortIcon
	let headers: [] = table.getElementsByTagName("th");
	for (let i = 0; i < headers.length; i++) {
		let header: HTMLElement = headers[i];
		if (header.children.length > 0) {
			let icons = header.getElementsByTagName("lena-p-icon");
			for (let j = 0; j < icons.length; j++) {
				let id = icons[j].getAttribute("id");
				let icon = document.getElementById(id);
				if (id === columnId + "_down" && dir === "desc") {
					icon.style.visibility = "visible";
				} else if (id === columnId + "_up" && dir === "asc") {
					icon.style.visibility = "visible";
				} else {
					icon.style.visibility = "hidden";
				}
			}
		}
	}

	return dir;
}
