import { RequestHeader } from "./RequestHeader";
import { User } from "../store/user";

const ApiUrl = process.env.REACT_APP_BACKEND_URL;

export async function getUser(token: string) : Promise<User>{
	return fetch(ApiUrl + "/getUser", {
		method: "GET",
		headers: RequestHeader.createGetHeader(token)
	})
	.catch((err) => {
		throw new Error(err);
	})
	.then(async (response) => {
		if (!response.ok) {
			throw new Error(await response.text());
		}
		return await response.json();
	});
}
