import React, { useCallback, useContext, useEffect, useState } from "react";
import Dropdown from "../../components/dropdown/dropdown";
import CustomLabel from "../../components/customLabel/customLabel";
import { BannerState, PBanner, PButton, PContentWrapper, PGrid, PGridItem, PSpinner, PText } from "@porsche-design-system/components-react";
import { CustomTable } from "../../components/customTable/customTable";
import { CustomTableHeader } from "../../components/customTable/customTableHeader";
import {
	cancelReservation,
	exportMyNumbers,
	getLimitationNumbersByOrderTypeDealer,
	getLimitationNumbersByOrderTypeImporter,
	saveDealerLimitationNumber
} from "../../api/LimitationNumberService";
import { LimitationNumber, SaveDealerReservationRequest, State } from "../../store/limitationNumber";
import { getQuoteByOrderTypeAndPartnerNumber } from "../../api/ModelImporterQuoteService";
import UserContext from "../../store/UserContext";
import { showDateTimeAsString } from "../../utils";
import { getDealersByImporter } from "../../api/DealerService";
import { QuotaInformation } from "./QuotaInformation";
import { Dealer } from "../../store/types";
import { useModelState } from "../../store/ModelContext";

type ModelBanner = {
	state: BannerState;
	title: string;
	description: string;
	duration: number;
};

interface ControlProps {
	triggerUpdate: boolean;
	activeTab : number;
}

export function MyNumbersTab(props : ControlProps) {

	const { partnerNumber, lenaAuthToken , isDealer} = useContext(UserContext);
	const modelContextState = useModelState();
	const [loading, setLoading] = useState(false);
	const tableId = "myReservationsTable";
	const [limitationNumbers, setLimitationNumbers] = useState<Array<LimitationNumber>>([]);
	const [isBannerShow, handleBannerShow] = useState(false);
	const [isDeleteBanner, handleCancelBannerShow] = useState(false);	
	const [reservationToCancelId, setReservationToCancelId] = useState(null);
	const [reservationToCancelNumber, setReservationToCancelNumber] = useState(null);
	const [ordersByChanceCount, setOrdersByChanceCount] = useState<number>();
	const [overAllQuote, setOverAllQuote] = useState<number>(null);
	const [assignedQuotes, setAssignedQuotes] = useState<number>(null);
	const [dealerList, setDealerList] = useState<Array<Dealer>>([]);


	const initialBanner: ModelBanner = {
		state: "neutral",
		title: "",
		description: "",
		duration: 6000
	};
	const [bannerInfo, handleBannerInfo] = useState(initialBanner);

	useEffect( () => {
		let dataList: Dealer[] = [];
		if(!isDealer){
			getDealersByImporter(lenaAuthToken, partnerNumber)
			.then(((dealers) => {
				dealers.forEach((dealer, index) => {
					dealer.toString = function (): string {
						return `${this.partnerNumber} - ${this.displayName}`;
					};
					dataList[dealer.partnerNumber]=dealer;
				})
			}))
			setDealerList(dataList);
		}
	}, [lenaAuthToken, partnerNumber, isDealer]);

	const callGetImporterQuoteByOrderTypeAndImporter = useCallback(() => {
		getQuoteByOrderTypeAndPartnerNumber(lenaAuthToken, modelContextState.globalOrderType, partnerNumber)
		.then((quote) => {
			setOverAllQuote(quote);
		})
		.catch((error) => {
			setOverAllQuote(null);
			console.log(error.message);
		});
	}, [modelContextState.globalOrderType, partnerNumber, lenaAuthToken]);

	const handleReservationResponse = useCallback((response: LimitationNumber[], partnerNumber) => {
		response = response.filter((item) => (item.reservationState === State.RESERVED || item.reservationState === State.BOOKED));
		setAssignedQuotes(response.filter((item) => item.limitedNumber > 0).length);

		let limitationNumbersWithoutByChance = response.filter((item) => item.limitedNumber !== 0);
		let limitationNumbersWithoutByChanceWithMappedDealer = limitationNumbersWithoutByChance.map(value => {
			if (value.dealerNumber != null && dealerList.length > 0) {
				let dealerFound  = dealerList.filter(item => item.partnerNumber === value.dealerNumber );
				if (dealerFound.length === 0) {
					let dealer: Dealer = {
						oid: "",
						displayName: "Nicht verfügbar",
						importer: "",
						partnerNumber: value.dealerNumber
					};
					dealer.toString = function (): string {
						return `${this.partnerNumber} - ${this.displayName}`;
					};
					dealerList[value.dealerNumber] = dealer;
				}
			}
			return value;
		});
		setLimitationNumbers(limitationNumbersWithoutByChanceWithMappedDealer);

		let countOrdersByChance = response.filter(
				(reservation) =>
						reservation.reservationState === State.BOOKED &&
					((!isDealer && reservation.importer === partnerNumber) || (isDealer && reservation.dealerNumber === partnerNumber)) &&
						reservation.limitedNumber === 0
		).length;
		setOrdersByChanceCount(countOrdersByChance);
	}, [dealerList, isDealer]);

	const loadReservations = useCallback(() => {
		setLimitationNumbers([]);
		if (modelContextState.globalOrderType !== "" && props.triggerUpdate && props.activeTab === 0) {
			if(isDealer){
				getLimitationNumbersByOrderTypeDealer(lenaAuthToken, modelContextState.globalOrderType, partnerNumber)
					.then((response) => {
						handleReservationResponse(response, partnerNumber);
					})
					.then(() => {
						callGetImporterQuoteByOrderTypeAndImporter();
					})
					.then(() => {
						setLoading(false);
					})
					.catch((error) => {
						console.log(error.message);
					});
				
			}else{
				getLimitationNumbersByOrderTypeImporter(lenaAuthToken, modelContextState.globalOrderType, partnerNumber)
				.then((response) => {
					handleReservationResponse(response, partnerNumber);
				})
				.then(() => {
					callGetImporterQuoteByOrderTypeAndImporter();
				})
				.then(() => {
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
				})
			}
			
			setLoading(true);
		}
	}, [modelContextState.globalOrderType, props.activeTab, props.triggerUpdate, partnerNumber, lenaAuthToken, isDealer, callGetImporterQuoteByOrderTypeAndImporter, handleReservationResponse]);


	/**
	 * Load limitation numbers
	 */
	useEffect(() => {
		loadReservations();
	}, [partnerNumber, loadReservations]);


	const showBanner = (message, resultState) => {
		let bannerInfo: ModelBanner = {
			state: resultState,
			title: "LENA Notification",
			description: message,
			duration: 6000
		};
		handleBannerInfo(bannerInfo);
		handleBannerShow(true);
		setTimeout(() => {
			handleBannerShow(false);
		}, bannerInfo.duration);
	};


	function handleCancelReservation(reservation: string, selectedReservation: number) {
		handleCancelBannerShow(false);
		setReservationToCancelId(reservation);
		setReservationToCancelNumber(selectedReservation)
		setTimeout(() => {
			handleCancelBannerShow(true);
		}, 100);
	}	

	function handleCancelReservationConfirm() {
		if (reservationToCancelId === null) return;
		cancelReservation(lenaAuthToken, reservationToCancelId)
			.then((result) => {
				setReservationToCancelId(null);
				handleCancelBannerShow(false);
				showBanner(result, "neutral");
				loadReservations();
			})
			.catch((error) => {
				console.log(error.message);
			});
	}

	function handleCancelReservationCancel() {	
		handleCancelBannerShow(false);
	}

	function onChangeDealer(index, value, reservation: LimitationNumber) {
		console.log("onChange");
		let saveDealerReservationRequest: SaveDealerReservationRequest = {
			importer: reservation.importer,
			reservationState: reservation.reservationState,
			orderType: reservation.orderType,
			dealer: value ? value : "",
			limitedNumber: reservation.limitedNumber
		};
		saveDealerLimitationNumber(lenaAuthToken, saveDealerReservationRequest)
			.then(() => {
				let reservationToUpdate = limitationNumbers.find((item) => item.limitedNumber === reservation.limitedNumber);
				reservationToUpdate.dealerNumber = value;
				console.log(limitationNumbers);
			})
			.catch((error) => console.log(error));
	}

	function onExport() {
		exportMyNumbers(lenaAuthToken, modelContextState.globalOrderType, partnerNumber)
			.then((response) => response.blob())
			.then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = url;
				a.download = modelContextState.globalOrderType + "_MyNumbers.csv";
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove(); //afterwards we remove the element again
			})
			.catch((error) => console.log(error));
	}

	return (
		<div className="wrapper">
			{isBannerShow && (
				<PBanner width="basic" state={bannerInfo.state} persistent={true} className="create-model__banner_position">
					<span slot="title">{bannerInfo.title}</span>
					<span slot="description">{bannerInfo.description}</span>
				</PBanner>
			)}
			{isDeleteBanner && (
				<PBanner
					width="basic"
					persistent={false}
					onDismiss={() => handleCancelReservationCancel()}
					className="create-model__banner_position delete-confirm-banner">
					<span slot="title" className="delete-confirm-banner__title">
						{"Do you want to cancel reservation " + reservationToCancelNumber + "?"}
					</span>
					<div slot="description" className="delete-confirm-banner__description">
						<PButton variant="primary" className="confirm-button" onClick={() => handleCancelReservationConfirm()}>
							Ok
						</PButton>
						<PButton className="confirm-button" onClick={() => handleCancelReservationCancel()}>
							Cancel
						</PButton>
					</div>
				</PBanner>
			)}
			<div>
				<PContentWrapper width={"extended"}>
					{loading && <PSpinner className={"spinner"} size={{ base: "small", l: "medium" }} />}
					{!loading && (
						<PContentWrapper className={"inner-content"} width={"extended"}>
							<PGrid>
								<PGridItem size={11}>
									{assignedQuotes !== null && overAllQuote !== null && (
										<QuotaInformation assignedQuotes={assignedQuotes} overAllQuote={overAllQuote} />
									)}
									{assignedQuotes !== undefined && ordersByChanceCount !== undefined && (
										<PText weight={"bold"}>Orders by chance: {ordersByChanceCount}</PText>
									)}
								</PGridItem>
								<PGridItem size={1}>
									{modelContextState.globalOrderType !== "" && (
										<PButton className="export-button" variant={"primary"} onClick={() => onExport()}>
											Export
										</PButton>
									)}
								</PGridItem>
							</PGrid>
							<CustomTable tableId={tableId} className={"my-numbers-table"}>
								<thead>
									<tr>
										<CustomTableHeader text={"No."} tableId={tableId} columnPos={0} sortable={true} />
										<CustomTableHeader text={"Reservation End"} tableId={tableId} columnPos={1} sortable={true} isDate={true}/>
										<CustomTableHeader text={"State"} tableId={tableId} columnPos={2} sortable={true} />
										<CustomTableHeader text={"Order"} tableId={tableId} columnPos={3} sortable={true} />
										{!isDealer && <CustomTableHeader text={"Dealer"} tableId={tableId} columnPos={4} sortable={true} />}
										<CustomTableHeader text={""} tableId={tableId} columnPos={5} sortable={false} />
									</tr>
								</thead>
								{limitationNumbers.length > 0 &&
								<tbody>
									{Array.from(limitationNumbers).map((reservation, index) => (
										<tr key={reservation.limitedNumber}>
											<td>{reservation.limitedNumber}</td>
											<td>{reservation.reservationState === State.RESERVED && showDateTimeAsString(reservation.endReservedDateTime)}</td>
											<td>{reservation.reservationState}</td>
											<td>{reservation.commissionNumber}</td>
											{!isDealer && reservation.reservationState === State.RESERVED && (
												<td width={"30%"}>
													<Dropdown
														placeholder="Dealer"
														value={reservation.dealerNumber}
														onChange={(value) => {
															if (reservation.dealerNumber !== value && reservation.orderType === modelContextState.globalOrderType) {
																onChangeDealer(index, value, reservation);
															}
														}}
														data={dealerList}
														multipleSelections={false}
														clearable={reservation.reservationState === State.RESERVED}
														searchable={true}
														showTooltip={false}
														labels={[<CustomLabel key="labe" position={"bottom-right"} text="Dealer" />]}
													/>
												</td>
											)}
											{!isDealer && reservation.reservationState === State.BOOKED && <td width={"30%"}>{reservation.dealerNumber}</td>}
											<td>
												{reservation.reservationState === State.RESERVED && (
													<PButton variant="primary" onClick={() => handleCancelReservation(reservation.id, reservation.limitedNumber)}>
														Cancel
													</PButton>
												)}
											</td>
										</tr>
									))}
								</tbody>
									}
							</CustomTable>
						</PContentWrapper>
					)}
				</PContentWrapper>
			</div>
		</div>
	);
}
