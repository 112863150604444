import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { PGrid, PGridItem, PText } from "@porsche-design-system/components-react";
import Datepicker from "../../components/datepicker/datepicker";
import CustomLabel from "../../components/customLabel/customLabel";
import Dropdown from "../../components/dropdown/dropdown";
import { getDateTimeAsString, splitDateTime } from "../../utils";
import { ImporterSos, SosDateImporters } from "../../store/importerSos";
import moment from "moment";

interface ControlProps {
	orderType: string;
	addToMarketDependentSosList: Function;
	//removeMarketDependentSosList: Function;
	totalMarketDependentSos: SosDateImporters[];
	itemIndex: number;
	importersSosList: ImporterSos[];
	marketDependentSosItem;
	updateView: boolean;
}

export function MarketDependentSos(props: ControlProps): ReactElement {
	const [importerList, setImporterList] = useState<string[]>([]);
	const [selectedImporter, setSelectedImporter] = useState<string>("");
	const [startOfSaleDate, setStartOfSaleDate] = useState<string>("");
	const [startOfSaleTime, setStartOfSaleTime] = useState<string>("");
	const [startOfSaleDateTime, setStartOfSaleDateTime] = useState<string>(
		props.marketDependentSosItem ? props.marketDependentSosItem.startOfSaleDateTime : ""
	);
	const [savedStartOfSaleDateInPast, setSavedStartOfSaleDateInPast] = useState<boolean>(false);
	const [startOfSaleDateValid, setStartOfSaleDateValid] = useState<boolean>(true);
	const [startOfSaleTimeValid, setStartOfSaleTimeValid] = useState<boolean>(true);
	const [dateInvalidErrorMessage, setDateInvalidErrorMessage] = useState<string>("");

	useEffect(() => {
		if (startOfSaleDateTime !== "") {
			let dateTime = splitDateTime(startOfSaleDateTime);
			let convertedDateTime = moment.utc(dateTime.date + " " + dateTime.time).local();
			setStartOfSaleDate(convertedDateTime.format("YYYY-MM-DD"));
			setStartOfSaleTime(convertedDateTime.format("HH:mm"));
			let now = moment.utc(moment.now()).unix();
			setSavedStartOfSaleDateInPast(convertedDateTime.unix() <= now ? true : false);
		}
	}, [startOfSaleDateTime]);

	useEffect(() => {
		let dataList: string[] = [];
		let importers: string[] = [];
		if (props.importersSosList.length > 0) {
			props.importersSosList.forEach((importer) => {
				if (startOfSaleDateTime !== "") {
					if (importer.startOfSaleDateTime === null || importer.startOfSaleDateTime === "") {
						dataList[importer.importer] = importer.importer + (importer.importerDescription ? " - " + importer.importerDescription : "");
					} else if (importer.startOfSaleDateTime === startOfSaleDateTime) {
						dataList[importer.importer] = importer.importer + (importer.importerDescription ? " - " + importer.importerDescription : "");
						importers.push(importer.importer);
					}
				} else {
					if (importer.startOfSaleDateTime === null || importer.startOfSaleDateTime === "") {
						dataList[importer.importer] = importer.importer + (importer.importerDescription ? " - " + importer.importerDescription : "") ;
					}
				}
				setImporterList(dataList);
				if (importers.length > 0) {
					setSelectedImporter(importers.join());
				}
			});
		}
	}, [props.importersSosList, startOfSaleDateTime]);

	function onChangeImporter(value, newStartOfSaleDate, newStartOfSaleTime) {
		if (checkDateTimeValid()) {
			setSelectedImporter(value);
			let startOfSaleDateTimeAsString = "";
			if(newStartOfSaleDate != null) {
				startOfSaleDateTimeAsString = getDateTimeAsString(newStartOfSaleDate, startOfSaleTime);
			}else if(newStartOfSaleTime != null){
				startOfSaleDateTimeAsString = getDateTimeAsString(startOfSaleDate, newStartOfSaleTime);
			}else{
				startOfSaleDateTimeAsString = getDateTimeAsString(startOfSaleDate, startOfSaleTime);
			}
			let sameDateExist = props.totalMarketDependentSos.filter((item) => item.startOfSaleDateTime === startOfSaleDateTimeAsString);
			if ( (sameDateExist.length > 0 && selectedImporter === "") || (sameDateExist.length > 1 && selectedImporter !== "" )) {
				setStartOfSaleDateValid(false);
				setDateInvalidErrorMessage("The defined date already exists.");
				setStartOfSaleDate("");
				return;
			}else{
				setStartOfSaleDateTime(startOfSaleDateTimeAsString);
				let selectedImporterSosList: ImporterSos[] = [];
				let selectedImporterList = [];

				selectedImporterList = value.split(",");
				selectedImporterList.forEach((importer: string) => {
					let importerSos = props.importersSosList.find((item: ImporterSos) => item.importer === importer);
					if (importerSos !== undefined) {
						importerSos.startOfSaleDateTime = startOfSaleDateTimeAsString;
						selectedImporterSosList.push(importerSos);
						props.addToMarketDependentSosList(props.itemIndex, startOfSaleDateTimeAsString, selectedImporterSosList);
					}else{
						props.addToMarketDependentSosList(props.itemIndex, null, []);
					}
				});
			}
		} else {
			setSelectedImporter("");
		}
	}

	const checkDateTimeValid = useCallback(() => {
		let dateErrorMessage = "Date is a mandatory field";
		if (startOfSaleDate === "" && startOfSaleTime === ""){
			setStartOfSaleDateValid(false);
			setStartOfSaleTimeValid(false);
		}
		else if ((startOfSaleDate !== "" || startOfSaleDate !== null) && (startOfSaleTime === "" || startOfSaleTime === null)) {
			setStartOfSaleDateValid(true);
			setStartOfSaleTimeValid(false);
			return false;
		} else if ((startOfSaleDate === "" || startOfSaleDate === null) && (startOfSaleTime !== "" || startOfSaleTime !== null)) {
			setStartOfSaleDateValid(false);
			setDateInvalidErrorMessage(dateErrorMessage);
			setStartOfSaleTimeValid(true);
			return false;
		} else {
			setStartOfSaleDateValid(true);
			setStartOfSaleTimeValid(true);
			return true;
		}
	}, [startOfSaleDate, startOfSaleTime]);

	useEffect(() => {
			checkDateTimeValid();
	}, [startOfSaleDate, startOfSaleTime, checkDateTimeValid]);

	function onChangeStartOfSaleTime(value) {
		setStartOfSaleTime(value);
		onChangeImporter(selectedImporter, null, value);
	}

	function onChangeStartOfSaleDate(value) {
		let validFormat = value ? value.split(".").join("-") : null;
		setStartOfSaleDate(validFormat);
		onChangeImporter(selectedImporter, validFormat, null);
	}

	/*function onDelete(){
		props.removeMarketDependentSosList(props.itemIndex);
	}*/

	return (
		<PGrid>
			<PGridItem size={6}>
				<div className={"create-model-details__input_container__subsection flex-row"}>
					<div className="flex-3">
						<Datepicker
							className={"create-model-details__input_section__field"}
							placeholder={"Please define SOS date"}
							value={startOfSaleDate}
							onChange={(value) => onChangeStartOfSaleDate(value)}
							errorMsg={dateInvalidErrorMessage}
							isError={!startOfSaleDateValid}
							dateFormat={"yyyy.MM.dd"}
							labels={[<CustomLabel key="labe" position={"bottom-right"} text="Market specific SOS" />]}
							showTooltip={false}
							disabled={props.updateView && savedStartOfSaleDateInPast}
						/>
					</div>
					<PText className={"custom-field create-model-details__input_section__field"}>
						<div className="custom-field__wrapper">
							<input
								className={startOfSaleTimeValid === true ? "timepicker-box text-field-input" : "timepicker-box text-field-input error-input"}
								name="startTime"
								value={startOfSaleTime}
								onChange={(event) => onChangeStartOfSaleTime(event.target.value)}
								type={"time"}
								disabled={props.updateView && savedStartOfSaleDateInPast}
							/>
						</div>
					</PText>
				</div>
			</PGridItem>
			<PGridItem size={6}>
				<Dropdown
					className={"create-model-details__input_section__field"}
					placeholder={"Importer"}
					value={selectedImporter}
					onChange={(value) => {
						if (selectedImporter !== value) {
							onChangeImporter(value, null, null);
						}
					}}
					data={importerList}
					multipleSelections={true}
					clearable={true}
					searchable={true}
					showTooltip={false}
					labels={[<CustomLabel key="labe" position={"bottom-right"} text="Importer" />]}
				/>
				{/*
				<PGrid>
					<PGridItem size={12} >
					</PGridItem>
					<PGridItem size={1} className={"delete-sos-icon-grid-item"}>
						<PButtonPure icon={"delete"} className={"delete-sos-icon"} onClick={onDelete} />
					</PGridItem>
				</PGrid>*/}
			</PGridItem>
		</PGrid>
	);
}
