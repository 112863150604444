export type User = {
	userRoles : Array<string>
	partnerNumber : string,
	dealer: boolean
}

export enum USER_ROLE {
	MASTER_DATA_MAINTENANCE = "LENA_Master_Data_Maintenance",
	ORDER_MANAGEMENT = "LENA_Order_Management",
	VIP_ORDER_MANAGEMENT = "LENA_VIP_Order_Management",
	REPORTING = "LENA_Reporting",
	IMPORTER = "LENA_Importer",
	DEALER = "LENA_Dealer"
}