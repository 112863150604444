import './datepicker.scss';

import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import CustomFieldWrapper from '../customFieldWrapper/customFieldWrapper';
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg';
import { PTextFieldWrapper } from "@porsche-design-system/components-react";

import "react-datepicker/dist/react-datepicker.css";

export interface IDatepickerProps {
    placeholder: string;
    value?: string,
    className?: string,
    onChange?: (value: string) => void;
    clearable?: boolean;
    dateFormat?: string;
    showMonthYearPicker?: boolean;
    disabled?: boolean;
    labels?: [React.ReactNode];
    showTooltip?: boolean;
    tooltip?: string;
    maskBeforeEdit?: boolean;
    isError?: boolean;
    errorMsg?: string;
}

const Datepicker = (props: IDatepickerProps) => {
    const datepickerRef = useRef(null);
    const [focused, setFocused] = useState(false)
    const momentFormat = props.dateFormat.replace(/y/g, 'Y').replace(/m/g, 'M').replace(/d/g, 'D');
    // console.log(props.isError, props.errorMsg)
    return (
        <PTextFieldWrapper
            state={props.isError === true ? "error" : "none"}>
            <CustomFieldWrapper
                className={props.className}
                clearable={props.clearable}
                clearAction={() => {
                    props.onChange && props.onChange('');
                }}
                showClear={!!props.value}
                placeholder={props.placeholder}
                hasValue={!!props.value || focused}
                disabled={props.disabled}
                labels={props.labels}
                showTooltip={props.showTooltip}
                tooltip={props.tooltip}
                maskBeforeEdit={props.maskBeforeEdit}
                fieldValue={props.value}
            >
                <DatePicker
                    className={props.isError === true ? "input-error" : ""}
                    selected={props.value ? moment(props.value, momentFormat).toDate() : null}
                    onChange={(date: Date) => {
                        const formatTedDate = date ? moment(date).format(momentFormat) : null;
                        props.onChange && props.onChange(formatTedDate);
                    }}
                    showPopperArrow={false}
                    dateFormat={props.dateFormat}
                    //dateFormat={"MMM dd yyyy"}
                    showMonthYearPicker={props.showMonthYearPicker}
                    showYearDropdown
                    showMonthDropdown
                    onCalendarClose={() => { setFocused(false) }}
                    onCalendarOpen={() => { setFocused(true) }}
                    ref={datepickerRef}
                    readOnly={props.disabled}
                />
                <Calendar
                    className={'custom-datepicker__icon'}
                    onClick={() => {
                        !props.disabled && datepickerRef.current.setOpen(true);
                    }}
                />
            </CustomFieldWrapper>
            <span slot="message" id="some-message-id" className="validation-message">{props.errorMsg}</span>
        </PTextFieldWrapper>
    )
};

Datepicker.defaultProps = {
    showMonthYearPicker: false,
    dateFormat: 'dd/MM/yyyy',
    disabled: false,
    clearable: false,
    showTooltip: false,
    maskBeforeEdit: false,
}

export default Datepicker;
