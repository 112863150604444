import { RequestHeader } from "./RequestHeader";
import { ImporterQuote } from "../store/types";

const ApiUrl = process.env.REACT_APP_BACKEND_URL;

/**
 * Returns importer quote over all years by orderType and importer
 * @param orderType
 * @param importer
 */
export function getQuoteByOrderTypeAndPartnerNumber(token: string, orderType: string, importer: string): Promise<number> {
	let request = {
		orderType: orderType,
		partnerNumber: importer
	};
	return fetch(ApiUrl + "/getQuoteByOrderTypeAndPartnerNumber", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(request)
	})
		.catch((err) => {
			throw new Error(err);
		})
		.then(async (response) => {
			if (!response.ok) {
				throw new Error(await response.text());
			}
			return await response.json();
		});
}

export function getImportersQuoteByOrderTypeAndQuoteYear(token: string, orderType: string, quoteYear: number): Promise<Array<ImporterQuote>> {
	let request = {
		orderType: orderType,
		quoteYear: quoteYear
	};
	return 	fetch(ApiUrl + "/getImportersQuoteByOrderTypeAndQuoteYear", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(request)
	})
	.catch((err) => {
		throw new Error(err);
	}).then(async (response) => {
			if (!response.ok) {
				throw new Error(await response.text());
			}
			return await response.json();
		});
}