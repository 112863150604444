import './customTooltip.scss';
import classnames from 'classnames';

import React, { useState } from 'react';

export interface ITooltipProps {
  tooltip?: string;
}

const CustomTooltip = (props: ITooltipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  return (
    <div
      className="tooltip_container"
      onMouseEnter={() => {
        setTooltipOpen(true);
      }}
      onMouseLeave={() => {
        setTooltipOpen(false);
      }}
    >
      {!!props.tooltip && (
        <>
          <div className={'tooltip_container__tooltip_icon'}>?</div>
          <div className={classnames('tooltip_container__tooltip', `${tooltipOpen ? 'visible' : ''}`)}>{props.tooltip}</div>
        </>
      )}
    </div>
  );
};

export default CustomTooltip;
