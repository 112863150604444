import React, { useState } from "react";
import { PTabsBar } from "@porsche-design-system/components-react";
import MaintainTab from "./MaintainTab";
import QuotaTab from "./QuotaTab";
import "./index.scss";

function Maintain() {
	const [triggerUpdate, handleTriggerUpdate] = useState(false);
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

	const handleTabChange = (e) => {
		handleTriggerUpdate(!triggerUpdate);
		let tabIndex = e.detail.activeTabIndex;
		setActiveTabIndex(tabIndex);
		switch (tabIndex) {
			case 0:
				document.getElementById("tab-panel-0").hidden = false;
				document.getElementById("tab-panel-1").hidden = true;
				break;
			case 1:
				document.getElementById("tab-panel-0").hidden = true;
				document.getElementById("tab-panel-1").hidden = false;
				break;
			default:
				document.getElementById("tab-panel-0").hidden = false;
				document.getElementById("tab-panel-1").hidden = true;
		}
	};

	return (
		<div>
			<PTabsBar activeTabIndex={activeTabIndex} onTabChange={handleTabChange} className="tab-bar">
				<button type={"button"} id={"tab-item-0"} aria-controls={"tab-panel-0"}>
					Model Maintenance
				</button>
				<button type={"button"} id={"tab-item-1"} aria-controls={"tab-panel-1"}>
					Quotas
				</button>
			</PTabsBar>

			<div className={"tab-panel"}>
				<div id="tab-panel-0" role="tabpanel" aria-labelledby="tab-item-0">
					<MaintainTab triggerUpdate={triggerUpdate} />
				</div>
				<div id="tab-panel-1" hidden role="tabpanel" aria-labelledby="tab-item-1">
					<QuotaTab triggerUpdate={triggerUpdate} />
				</div>
			</div>
		</div>
	);
}

export default Maintain;
