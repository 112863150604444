import React, { ReactElement } from "react";
import { PFlex, PText } from "@porsche-design-system/components-react";

interface ControlProps {
	assignedQuotes: number;
	overAllQuote: number;
}

export function QuotaInformation(props: ControlProps): ReactElement {
	return (
		<PFlex direction={"row"} justifyContent={"flex-start"}>
			<PText weight={"bold"} className={"quotesText"}>
				Available quotas: {props.overAllQuote - props.assignedQuotes}  of  {props.overAllQuote}
			</PText>
			{props.overAllQuote - props.assignedQuotes < 0 && (
				<PText weight={"bold"} className={"quotesText warning"} color={"notification-error"}>
					Reserved/booked limitation numbers exceed quotas
				</PText>
			)}
		</PFlex>
	);
}