export function changeOrderType(value: string): ChangeAction {
	return {
		type: "CHANGE_IDENT",
		value
	};
}

export function changeDescription(value: string): ChangeAction {
	return {
		type: "CHANGE_DESCRIPTION",
		value
	};
}

export function changeStartDate(value: string): ChangeAction {
	return {
		type: "CHANGE_START_DATE",
		value
	};
}

export function changeStartTime(value: string): ChangeAction {
	return {
		type: "CHANGE_START_TIME",
		value
	};
}

export function changeStartOfSaleDate(value: string): ChangeAction {
	return {
		type: "CHANGE_START_OF_SALE_DATE",
		value
	};
}

export function changeStartOfSaleTime(value: string): ChangeAction {
	return {
		type: "CHANGE_START_OF_SALE_TIME",
		value
	};
}

export function changeMaxLimitedNumber(value: string): ChangeAction {
	return {
		type: "CHANGE_MAX_LIMITED_NUMBER",
		value
	};
}

export function switchToUpdateModel(valueOrderType: string, valueDescription: string, valueStartDateTime: string, valueLimitedNumber: string, valueStartOfSaleDateTime: string, valueImporterStartOfSaleDateTime: string []): SwitchToUpdateAction {
	return {
		type: "SWITCH_TO_UPDATE_MODEL",
		valueOrderType: valueOrderType,
		valueDescription: valueDescription,
		valueStartDateTime: valueStartDateTime,
		valueLimitedNumber: valueLimitedNumber,
		valueStartOfSaleDateTime: valueStartOfSaleDateTime,
		valueImporterStartOfSaleDateTime: valueImporterStartOfSaleDateTime
	};
}

export function doSubmit(): SubmitAction {
	return {
		type: "SUBMIT"
	};
}

export function doDelete(): DeleteAction {
	return {
		type: "DELETE"
	};
}

export function doReset(): ResetAction {
	return {
		type: "RESET_FORM"
	};
}

export function showBannerMsg(isBannerShow: boolean, title: string, desc: string, state: string): BannerAction {
	return {
		type: "SHOW_BANNER",
		isBannerShow,
		title,
		desc,
		state
	};
}

type ResetAction = Readonly<{
	type: "RESET_FORM";
}>;

type ChangeAction = Readonly<{
	type: "CHANGE_IDENT" | "CHANGE_DESCRIPTION" | "CHANGE_START_DATE" | "CHANGE_START_TIME" | "CHANGE_START_OF_SALE_DATE" | "CHANGE_START_OF_SALE_TIME" | "CHANGE_MAX_LIMITED_NUMBER";
	value: string;
}>;

type SwitchToUpdateAction = Readonly<{
	type: "SWITCH_TO_UPDATE_MODEL";
	valueOrderType: string;
	valueDescription: string;
	valueStartDateTime: string;
	valueLimitedNumber: string;
	valueStartOfSaleDateTime: string;
	valueImporterStartOfSaleDateTime: string[];
}>;

type SubmitAction = Readonly<{
	type: "SUBMIT";
}>;

type DeleteAction = Readonly<{
	type: "DELETE";
}>;

type BannerAction = Readonly<{
	type: "SHOW_BANNER";
	isBannerShow: boolean;
	title: string;
	desc: string;
	state: string;
}>;

export type LenaAction = Readonly<ChangeAction | SubmitAction | ResetAction | DeleteAction | SwitchToUpdateAction | BannerAction>;