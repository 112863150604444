import { RequestHeader } from "./RequestHeader";
import { ImporterSos } from "../store/importerSos";
import { Importer } from "../store/importer";

const ApiUrl = process.env.REACT_APP_BACKEND_URL;


/**
 * Returns importer sos by orderType
 * @param orderType
 */
export function getImportersSosByOrderType(token: string, orderType: string): Promise<ImporterSos[]> {
	let request = {
		orderType: orderType
	};
	return fetch(ApiUrl + "/getImportersSosByOrderType", {
		method: "POST",
		headers: RequestHeader.createPutHeader(token),
		body: JSON.stringify(request)
	})
	.catch((err) => {
		throw new Error(err);
	})
	.then(async (response) => {
		if (!response.ok) {
			throw new Error(await response.text());
		}
		return await response.json();
	});
}

/**
 * Returns importer sos by orderType
 * @param orderType
 */
export function getAllImporter(token: string): Promise<Importer[]> {
	return fetch(ApiUrl + "/getAllImporter", {
		method: "GET",
		headers: RequestHeader.createGetHeader(token),
	})
	.catch((err) => {
		throw new Error(err);
	})
	.then(async (response) => {
		if (!response.ok) {
			throw new Error(await response.text());
		}
		return await response.json();
	});
}
