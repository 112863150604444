import React from 'react'
import { PDivider, PMarque } from "@porsche-design-system/components-react";

function AppHeader() {
	return (
		<div className={"app-header"}>
			<PMarque className={"app-logo"} size={"medium"}></PMarque>
			<PDivider className={"divider"} />
		</div>
	);
}
export default AppHeader;