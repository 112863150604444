import React, { useContext, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import { PGrid, PGridItem, PDivider, PLinkPure } from "@porsche-design-system/components-react";
import MissingPage from "../pages/MissingPage";
import { getRoutes } from "../route";
import "./AppMain.scss";
import UserContext from "../store/UserContext";

function AppMain() {
	const { roles } = useContext(UserContext);
	const routes = getRoutes(roles)
	const [curPath, handleCurrentPath] = useState(window.location.pathname === "/" ? routes[0].path : window.location.pathname);

	function handleMenuClick() {
		const pathname = window.location.pathname;
		handleCurrentPath(pathname);
	}

	return (
			<div className="app-main">
				<Router>
						<PGrid>
							<PGridItem size={2}>
								<PGrid direction="column">
									{routes.map((route) => (
										<PGridItem size={12} key={route.path}>
											<PLinkPure className="AppNavItem" active={curPath === route.path} onClick={() => handleMenuClick()}>
												<Link to={route.path} children={route.name} />
											</PLinkPure>
											<PDivider className="AppMain-divider" />
										</PGridItem>
									))}
								</PGrid>
							</PGridItem>
							<PGridItem size={10} >
								<Switch>
									{routes.map((route) => (
										<Route key={route.path} {...route} />
									))}
									<Route path="/" exact>
										<Redirect to={routes[0].path} />
									</Route>
									<Route path="/">
										<MissingPage />
									</Route>
								</Switch>
							</PGridItem>
						</PGrid>
				</Router>
			</div>
	);
}
export default AppMain;
