import React, { ReactElement } from "react";
import { PFlex, PFlexItem, PGrid, PGridItem, PRadioButtonWrapper } from "@porsche-design-system/components-react";
import TextField from "../../components/textField/textField";

interface ControlProps {
	setSearchedLimitedNumber: Function;
	searchedLimitedNumber: string;
	loadLimitationNumber: Function;
	searchOption: string;
	setSearchOption: Function;
	excludedNumber: string;
	setExcludedNumber: Function;
	includedNumber: string;
	setIncludedNumber: Function;
}

export function SearchFields(props: ControlProps): ReactElement {
	return (
		<PGrid className={"select-box"} direction={"row"}>
			<PGridItem size={3}>
				<PFlex direction={"row"} className={"search-limitation-number-flex"}>
					<PRadioButtonWrapper hideLabel={true} className={"search-radio"}>
						<input type="radio" name="search" defaultChecked={true} value={"1"} onChange={(event) => props.setSearchOption(event.target.value)} />
					</PRadioButtonWrapper>
					<TextField
						placeholder={"Limitation Number"}
						isError={false}
						value={props.searchedLimitedNumber}
						disabled={props.searchOption !== "1"}
						onChange={(value) => {
							// check the value 001 or it is a number
							props.setSearchedLimitedNumber(value);
							if(value.indexOf(",") > -1){
								props.loadLimitationNumber(value);
							}else{
								props.loadLimitationNumber(Number(value === "" ? 0 : value));
							}
						}}
					/>
				</PFlex>
				<PFlex direction={"row"} className={"search-limitation-number-flex"}>
					<PRadioButtonWrapper label="tens, hundreds, thousands" hideLabel={false} className={"search-radio"}>
						<input type="radio" name="search" value={"2"} onChange={(event) => props.setSearchOption(event.target.value)} />
					</PRadioButtonWrapper>
				</PFlex>
			</PGridItem>

			<PGridItem size={3}>
				<PFlex direction={"row"} className={"search-limitation-number-flex"}>
					<PRadioButtonWrapper label="sequence of numbers (i.e. 123, 567)" hideLabel={false} className={"search-radio"}>
						<input type="radio" name="search" value={3} onChange={(event) => props.setSearchOption(event.target.value)} />
					</PRadioButtonWrapper>
				</PFlex>
				<PFlex direction={"row"} className={"search-limitation-number-flex only-radio"}>
					<PRadioButtonWrapper label="all digits identical (i.e. 333, 1111)" hideLabel={false} className={"search-radio"}>
						<input type="radio" name="search" value={4} onChange={(event) => props.setSearchOption(event.target.value)} />
					</PRadioButtonWrapper>
				</PFlex>
			</PGridItem>

			<PGridItem size={3}>
				<PFlex direction={"row"} className={"search-limitation-number-flex"}>
					<PFlexItem>
						<PRadioButtonWrapper label="include" hideLabel={false} className={"search-radio"}>
							<input type="radio" name="search" value={5} onChange={(event) => props.setSearchOption(event.target.value)} />
						</PRadioButtonWrapper>
					</PFlexItem>
					<PFlexItem>
						<TextField
							placeholder={"Limitation Number"}
							isError={false}
							value={props.includedNumber}
							disabled={props.searchOption !== "5"}
							onChange={(value) => {
								// check the value 001 or it is a number
								props.setIncludedNumber(value);
								props.loadLimitationNumber(Number(value === "" ? 0 : value));
							}}
						/>
					</PFlexItem>
				</PFlex>
				<PFlex direction={"row"} className={"search-limitation-number-flex"}>
					<PRadioButtonWrapper label="exclude" hideLabel={false} className={"search-radio"}>
						<input type="radio" name="search" value={6} onChange={(event) => props.setSearchOption(event.target.value)} />
					</PRadioButtonWrapper>
					<TextField
						placeholder={"Limitation Number"}
						isError={false}
						value={props.excludedNumber}
						disabled={props.searchOption !== "6"}
						onChange={(value) => {
							// check the value 001 or it is a number
							props.setExcludedNumber(value);
							props.loadLimitationNumber(Number(value === "" ? 0 : value));
						}}
					/>
				</PFlex>
			</PGridItem>

			<PGridItem size={3}>
				<PFlex direction={"row"} className={"search-limitation-number-flex"}>
					<PRadioButtonWrapper label="lowest available" hideLabel={false} className={"search-radio"}>
						<input type="radio" name="search" value={7} onChange={(event) => props.setSearchOption(event.target.value)} />
					</PRadioButtonWrapper>
				</PFlex>
				<PFlex direction={"row"} className={"search-limitation-number-flex only-radio"}>
					<PRadioButtonWrapper label="highest available" hideLabel={false} className={"search-radio"}>
						<input type="radio" name="search" value={8} onChange={(event) => props.setSearchOption(event.target.value)} />
					</PRadioButtonWrapper>
				</PFlex>
			</PGridItem>
		</PGrid>
	);
}
