import React, { ReactElement, useContext, useState } from "react";
import { BannerState, PBanner, PButton, PContentWrapper, PSpinner } from "@porsche-design-system/components-react";
import { exportAllLimitationNumbers } from "../../api/LimitationNumberService";
import UserContext from "../../store/UserContext";
import { useModelState } from "../../store/ModelContext";
import { USER_ROLE } from "../../store/user";

interface Banner {
	state: BannerState;
	title: string;
	description: string;
	duration?: number;
}

export function AllLimitationNumbers(props): ReactElement {
	const { roles, lenaAuthToken } = useContext(UserContext);
	const modelContextState = useModelState();
	const [loading, setLoading] = useState<boolean>(false);
	const [showButton] = useState<boolean>(roles.includes(USER_ROLE.REPORTING));

	const [isBannerShow, setBannerShow] = useState(false);
	const [bannerInfo, setBannerInfo] = useState<Banner>({
		state: "neutral",
		title: "LENA Notification",
		description: "",
		duration: 6000
	});

	function showBanner(message, resultState) {
		let bannerInfo: Banner = {
			state: resultState,
			title: "LENA Notification",
			description: message,
			duration: 6000
		};
		setBannerInfo(bannerInfo);
		setBannerShow(true);

		const timer = setTimeout(() => {
			setBannerShow(false);
		}, bannerInfo.duration);
		return () => clearTimeout(timer);
	}

	function onExportAllLimitationNumbers() {
		if (modelContextState.globalOrderType === "") {
			showBanner("Please select a model.", "error");
		} else {
			setLoading(true);
			exportAllLimitationNumbers(lenaAuthToken, modelContextState.globalOrderType)
				.then((response) => response.blob())
				.then((blob) => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement("a");
					a.href = url;
					a.download = modelContextState.globalOrderType + "_LimitationNumbers.csv";
					document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click();
					a.remove(); //afterwards we remove the element again
				})
				.catch((error) => console.log(error))
				.finally(() => setLoading(false));
		}
	}

	return (
		<PContentWrapper width={"extended"}>
			{isBannerShow && (
				<PBanner width="basic" persistent={true} state={bannerInfo.state} className="info-banner">
					<span slot="title">{bannerInfo.title}</span>
					<span slot="description">{bannerInfo.description}</span>
				</PBanner>
			)}
			<div className={"content-div"}>
				{showButton && (
					<PButton className={"export-all-limitNr-btn"} variant={"primary"} onClick={() => onExportAllLimitationNumbers()}>
						Export
					</PButton>
				)}
			</div>
			{loading && <PSpinner size={{ base: "small", l: "medium" }} />}
		</PContentWrapper>
	);
}
