import React, { ReactElement, useEffect, useState } from "react";
import { PText } from "@porsche-design-system/components-react";

interface State {
	time: number;
	minutes: number;
	seconds: number;
}

interface ControlProps {
	time: number;
	onTimeExpiration: Function;
}

export function Timer(props: ControlProps): ReactElement {

	const [state, setState] = useState<State>({
		time: props.time,
		seconds: Math.floor((props.time - 1) / 60),
		minutes: props.time - Math.floor((props.time - 1) / 60) * 60 - 1
	});
	const [show, setShow] = useState<boolean>(false);

	useEffect(() => {

		const timer = setTimeout(() => {
			if (state.time === 0) {
				//props.onTimeExpiration();
				return;
			}
			setState({
				time: state.time - 1,
				seconds: state.time - Math.floor((state.time - 1) / 60) * 60 - 1,
				minutes: Math.floor((state.time - 1) / 60)
			});
			setShow(true);
		}, 1000);

		return () => clearTimeout(timer);
	}, [state.time, props]);

	return (
		<div>
			{show && <PText id={"timer"} className={"timer"}>{`${state.minutes} : ${state.seconds < 10 ? `0${state.seconds}` : state.seconds}`}</PText>}
		</div>
	);
}
