import React, { useEffect, useState } from "react";
import {
	PContentWrapper,
	PFlex,
	PFlexItem,
	PHeadline
} from "@porsche-design-system/components-react";
import AppMain from "./layout/AppMain";
import AppHeader from "./layout/AppHeader";
import "./App.scss";
import { UserProvider } from "./store/UserContext";
import { getUser } from "./api/UserService";
import Cookies from "universal-cookie";
import Dropdown from "./components/dropdown/dropdown";
import { useModelDispatch, useModelState } from "./store/ModelContext";
import { getModels } from "./api/ModelService";

export function App() {
	const [isSizeOver, handleSizeOverState] = useState(false);
	const [userRoles, setUserRoles] = useState<Array<string>>([]);
	const [partnerNumber, setPartnerNumber] = useState<string>("");
	const [isDealer, setDealer] = useState<Boolean>(undefined);
	const [lenaAuthToken, setLenaAuthToken] = useState<string>("");
	const [modelList, setModelList] = useState({});
	const modelContextDispatch = useModelDispatch();
	const modelContextState = useModelState();

	useEffect(() => {
		const cookies = new Cookies();
		let lenaAuthCookie = cookies.get("LENAAuth");
		setLenaAuthToken(lenaAuthCookie);

		if (lenaAuthCookie !== "") {
			getUser(lenaAuthCookie)
				.then((user) => {
					setUserRoles(user.userRoles);
					setPartnerNumber(user.partnerNumber);
					setDealer(user.dealer);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			console.log("Authentication token is missing.");
		}
	}, [lenaAuthToken]);

	useEffect(() => {
		function handleWindowSize() {
			// real time window resize handler
			if (window.innerWidth <= 1000) handleSizeOverState(true);
			else handleSizeOverState(false);
		}

		//define event listener
		window.addEventListener("resize", handleWindowSize);
		return () => {
			window.removeEventListener("resize", handleWindowSize);
		};
	});

	useEffect(() => {
		let dataList: string[] = [];
		if (lenaAuthToken !== "" && modelContextState.doUpdate) {
			getModels(lenaAuthToken)
				.then((models) => {
					Array.from(models).forEach(({ description, orderType }: any) => {
						dataList[orderType] = orderType + "-" + description;
					});
					setModelList(dataList);
				})
				.catch((error) => {
					console.log(error);
				});
			modelContextDispatch({ type: "UPDATE_MODEL_LIST", doUpdate: false });
		}
	}, [modelContextState.doUpdate, lenaAuthToken, modelContextDispatch]);

	return (
		<PContentWrapper width={"extended"} className={"App"}>
			{isSizeOver === true ? (
				<PFlex justifyContent="center" alignItems="center">
					<PFlexItem>
						<PHeadline variant="headline-3" tag="h1">
							Please increase your screen size.
						</PHeadline>
					</PFlexItem>
				</PFlex>
			) : (
				<div>
					{lenaAuthToken !== "" && userRoles.length > 0 && partnerNumber !== "" && isDealer !== undefined && (
						<UserProvider roles={userRoles} partnerNumber={partnerNumber} lenaAuthToken={lenaAuthToken} isDealer={isDealer}>
							<Dropdown
								className={"model-select"}
								placeholder={"Model"}
								value={""}
								onChange={(value) => {
									if(value !== modelContextState.globalOrderType){
										modelContextDispatch({ type: "SELECT_ORDER_TYPE", orderType: value });
									}
								}}
								data={modelList}
								multipleSelections={false}
								clearable={false}
								searchable={true}
							/>
							<AppHeader />
							<AppMain />
						</UserProvider>
					)}
				</div>
			)}
		</PContentWrapper>
	);
}
export default App;
