import React, { useContext, useState } from "react";
import { PTabsBar } from "@porsche-design-system/components-react";
import "./index.scss";
import UserContext from "../../store/UserContext";
import { USER_ROLE } from "../../store/user";
import VipReservationTab from "./VipReservationTab";

export default function Administration() {
	const { roles } = useContext(UserContext);
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
	const [showMarketVipReservation] = useState<boolean>(roles.includes(USER_ROLE.VIP_ORDER_MANAGEMENT));

	const handleTabChange = (e) => {
		let tabIndex = e.detail.activeTabIndex;
		setActiveTabIndex(tabIndex);
	};

	return (
	<div style={{position:"relative"}}>
		<PTabsBar activeTabIndex={activeTabIndex} onTabChange={handleTabChange} className="tab-bar">
			<button type={"button"} id={"tab-item-0"} aria-controls={"tab-panel-0"}>
				Market VIP Reservation
			</button>
		</PTabsBar>

		<div className={"tab-panel"}>
			{showMarketVipReservation && ( <div id="tab-panel-0" role="tabpanel" aria-labelledby="tab-item-0">
				<VipReservationTab/>
			</div> )}
		</div>
	</div>
	);
}
